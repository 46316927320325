.sidePanel {
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    bottom: 0;
    width: 376px;
    transform: translateX(376px);
    transition: all 0.3s ease;
    z-index: 25;
}

.sidePanel--active {
    transform: translateX(0);
    box-shadow: -17px 0 94px rgba(0, 0, 0, 0.2);
}

.sidePanel-header {
    background: $r2-blue;
    padding: 24px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    color: white;
}

.sidePanel-header--withTool {
    padding-left: 76px;
}

.sidePanel-header-tool {
    position: absolute;
    top: 50%;
    left: 24px;
    background: $r2-blue-50;
    color: white;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-top: -18px;
    text-decoration: none;

    &:hover,
    &:focus {
        color: white;
        text-decoration: none;
    }
}

.sidePanel-header-avatar {
    width: 32px;
    height: 32px;
    margin: 0 10px 0 0;

    img {
        border-radius: 50%;
        max-width: 100%;
    }
}

.sidePanel-tools {
    display: flex;
    margin-bottom: 15px;
}

.sidePanel-content {
    padding: 24px;
}