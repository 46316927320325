.offer-products {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
}

	.offer-product {
		width: 25%;
		padding: 0 10px;
		margin-bottom: 30px;
		text-align: center;
	}

		.offer-product-title {
			font-size: 16px;
			margin-bottom: 5px;
		}

		.offer-product-dimensions {
			font-size: 12px;
			color: $r2-purple;
			margin-bottom: 10px;
		}

		.offer-product-options {
			margin: 10px 10px 0;
		}

			.offer-product-option {
				cursor: pointer;
				padding: 5px 10px;
				position: relative;

				small {
					font-size: 12px;
					display: block;
				}

				&.box-card--active {
					background: $r2-blue;
					color: white;

					//&:before {
					//	position: absolute;
					//	width: 24px;
					//	height: 24px;
					//	background: white url("/images/icons/icon-check-green.svg") center center no-repeat;
					//	background-size: 12px;
					//	border: 1px solid $r2-border;
					//	border-radius: 50%;
					//	left: -15px;
					//	top: -5px;
					//	z-index: 1;
					//	content: "";
					//}
				}
			}

.offer-bundles {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px -16px;
}

	.offer-bundles-empty {
		padding: 0 8px 16px;
	}

	.offer-bundle {
		width: 33.33%;
		padding: 0 8px;
		margin-bottom: 16px;
		display: flex;

		.box-card {
			position: relative;
			width: 100%;
			cursor: pointer;

			&:before {
				position: absolute;
				left: 15px;
				bottom: 15px;
				width: 20px;
				height: 20px;
				border-radius: 4px;
				content: "";
				background: $r2-gray-border;
			}
		}

		.box-card--active {
			&:before {
				background: $r2-blue;
			}

			&:after {
				width: 10px;
				height: 8px;
				background: url("/images/icons/icon-check.svg") center center no-repeat;
				background-size: contain;
				position: absolute;
				bottom: 21px;
				left: 20px;
				content: "";
			}
		}
	}

		.offer-bundle-title {
			font-size: 16px;
		}

		.offer-bundle-price {
			text-align: right;
			font-size: 16px;
			font-weight: bold;
		}

		.offer-bundle-discount {
			font-size: 12px;
			color: white;
			height: 20px;
			border-radius: 10px;
			line-height: 20px;
			position: absolute;
			top: -10px;
			right: 10px;
			background: $r2-blue;
			padding: 0 10px;
		}

.offer-summary {
	hr {
		margin-top: 20px;
		margin-bottom: 20px;
		border-top: 1px solid $r2-border;
	}
}

	.offer-summary-box {
		margin-bottom: 8px;
		background: $r2-table-hover-bg;
		border: 1px solid $r2-border;
		border-radius: $border-radius;
		padding: 16px;

		h2,
		h3 {
			font-size: 18px;
			margin: 0 0 10px;
		}
	}

	.offer-summary-prices {
		margin: 25px 0 15px;
	}

		.offer-summary-price {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}

				.offer-summary-price--totals {
					font-size: 1.2rem;
					font-weight: bold;
				}

	.offer-summary-action {
		margin-top: 15px;
	}
