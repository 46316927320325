.todoBar {
	margin: 0px 0 24px;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.btn {
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.task-completed {
    text-decoration: line-through;
    opacity: .3;
}
