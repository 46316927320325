.boxGrid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.boxGrid--offsetTop {
    margin-top: 30px !important;
}

.boxGrid--narrow {
    margin: 0 -8px;

    .boxGrid-col {
        padding: 0 8px;
        margin-bottom: 16px;
        display: flex;

        .box-card {
            width: 100%;
            margin: 0;
        }
    }
}


.boxGrid--offsetTop {
    margin-top: 30px !important;
}

.boxGrid--narrow {
    margin: 0 -8px;

    .boxGrid-col {
        padding: 0 8px;
        margin-bottom: 16px;
        display: flex;

        .box-card {
            width: 100%;
            margin: 0;
        }
    }
}

.boxGrid-col {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        width: 33.33%;
    }

    &:last-child {
        @include media-breakpoint-up(md) {
            width: 33.34%;
        }
    }
}


.boxGrid-col--fullHeight {
    display: flex;
    margin-bottom: 0px;

    .box {
        width: 100%;
    }
}

.boxGrid-col--large {
    width: 100% !important;

    @include media-breakpoint-up(sm) {
        width: 50% !important;
    }


}

.boxGrid-col--flex {
    display: flex;
}

.boxGrid-col--60 {
    width: 60% !important;
}

.boxGrid-col--40 {
    width: 40% !important;
}

.boxGrid-col--main {
    width: 100% !important;

    @include media-breakpoint-up(sm) {
        width: 75% !important;
    }
}

.boxGrid-col--aside {
    width: 100% !important;

    @include media-breakpoint-up(sm) {
        width: 25% !important;
    }
}

.boxGrid-col--half {
    width: 100% !important;

    @include media-breakpoint-up(sm) {
        width: 50% !important;
    }
}

.box {
    width: 100%;
    background: white;
    border-radius: $border-radius;
    box-shadow: 0 2px 0 0 #F0F0F3;
}

.box--offset {
    margin-bottom: 30px;
}

.box--login {
    min-width: 400px;

    .form-check-input {
        -webkit-appearance: checkbox;
    }
}

.box--offsetTop {
    margin-top: 8px;
}

.box--empty {
    background: $r2-table-hover-bg;
    border: 1px dashed $r2-border;
    text-align: center;
    margin-bottom: 15px;
}

.box--emptyNarrow {
    .box-content {
        padding: 5px 15px;
    }
}

.box--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.box--hiddenMobile {
    @include media-breakpoint-down(sm) {
        background: none !important;
        border: none !important;
        border-radius: 0px !important;
        box-shadow: none !important;
        margin: 0px -20px 0 -20px !important;
        width: auto;
    }
}

.box--narrowContent {
    .summary,
    .boxSet {
        @include media-breakpoint-up(lg) {
            max-width: 70%;
        }
    }
}

.box--support {
    padding-bottom: 160px;
    position: relative;

    &:before {
        position: absolute;
        height: 200px;
        right: 20px;
        bottom: -48px;
        width: 180px;
        //background: url("/images/support-praxis.png") left bottom no-repeat;
        //background-size: contain;
        content: "";
    }
}

.box--tabsOnly {
    margin-top: 8px;

    .box-tabs {
        padding-top: 0;
        border-bottom: none;

        .nav-link {
            font-size: 18px;
            padding: 18px 2px 18px;
        }
    }
}

.box-tabs {
    padding: 24px 24px 0 24px;
    border-bottom: 1px solid $r2-border;

    &:not(.box-tabs--nakedMobile) {
        @include media-breakpoint-down(sm) {
            padding: 12px 20px 0 20px;
            border: 1px solid $r2-border;
            border-radius: $border-radius $border-radius 0 0;
            width: 100%;
            background: white;
            overflow: scroll;
            flex-wrap: nowrap;

            .nav-link {
                white-space: nowrap;
            }
        }
    }


    h2,
    h3 {
        width: 100%;
    }

    .nav-item {
        margin: 0 20px 0 0px;


        &:last-child {
            margin-right: 0;
        }
    }

    .nav-link {
        border-radius: 0;
        padding: 5px 2px 15px;
        font-size: 16px;
        font-weight: bold;
        color: $r2-purple;
        border: none;
        position: relative;

        &.disabled {
            color: $r2-purple-20;
            opacity: 1;
        }

        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            border-radius: 3px 3px 0 0;
            background: $r2-blue;
            background: var(--primary);
            content: "";
            display: none;
        }

        &.active {
            background: none;
            color: $r2-black;

            &:before {
                display: block;
            }
        }

        &:hover,
        &:focus {
            &:before {
                display: block;
            }
        }
    }
}

.box-tabs--standalone {
    margin: 0 -20px 20px;
    width: calc(100% + 40px);

    @include media-breakpoint-up(md) {
        margin: 0 0 30px;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.box-tabs--inContent {
    margin: 0 -24px 20px;
    width: calc(100% + 40px);
    padding-top: 0 !important;
}

.box-tabs--second {

    .nav-item {
        margin-left: 0px;
    }

    .nav-link {
        font-size: 14px;
    }
}

.box-anchors {
    margin-bottom: 20px;

    .nav-item {
        margin: 0 20px 0 0;
    }

    .nav-link {
        padding: 5px 0;
        font-weight: bold;
        font-size: 18px;
    }
}

.box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 0;
    min-height: 58px;

    @include media-breakpoint-down(sm) {
        padding: 20px 20px 0;
    }

    h1,
    h2,
    h3 {
        margin: 0;

        small {
            font-size: 14px;
            font-weight: normal;
            color: $r2-purple;
            display: block;
        }
    }

    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }
}

.box-header--outlined {
    border-bottom: 1px solid $r2-border;
    display: block;
    padding-bottom: 0;
}

.box-header--conversations {
    padding: 20px 16px 0;
}

.box-filters {

    padding: 24px 24px 0;
}

.box-filters-header {
    display: flex;
    justify-content: space-between;
}

.box-filters-mainFilters {
    display: flex;
}

.box-filters-mainFilter {
    .control-label {
        display: none !important;
    }

    .col-sm-10 {
        flex: none !important;
        max-width: 100% !important;

        > div {
            display: flex;
        }
    }

    .form-group {
        margin: 0;
    }

    .form-check {
        padding: 0;
        margin: 0;

        input {
            display: none;
        }

        label {
            padding: 0.4375rem 0.875rem;
            color: $r2-purple;
            font-weight: bold;
            border: 1px solid transparent;
            border-radius: $border-radius;
            cursor: pointer;
        }

        input:checked + label {
            border-color: $r2-border;
            color: $r2-black;
        }
    }

    &:not(:first-child) {
        padding-left: 0.875rem;
    }
}


.box-filters-dropdown {
}

.box-filters-dropdown-subFilters {
    border: 1px solid $r2-border;
    padding: 15px;
    border-radius: $border-radius;
    margin-top: 15px;
}

.box-content {
    padding: 24px;

    @include media-breakpoint-down(sm) {
        padding: 20px;
    }

    .box-table {
        margin: 0 -24px;
        padding: 0;

        @include media-breakpoint-down(sm) {
            margin: 0 -20px;
        }

        .table-wrapper {
            padding: 0 24px;

            @include media-breakpoint-down(sm) {
                padding: 0 24px;
            }
        }
    }

    hr {
        margin: 24px -24px 24px -24px;
        border-top: 1px solid $r2-border;
    }
}

.box-content--inset {
    padding-top: 0;
    margin-top: -24px;

    @include media-breakpoint-down(sm) {
        margin-top: -20px;
    }
}

.box-content--divided {
    border-top: 1px solid $r2-border;
}

.box-content--narrowTop {
    padding-top: 8px;
}

.box-content-tools {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    .btn {
        margin-left: 2px;
    }
}

.box-actions {
    padding: 0 24px 24px;

    @include media-breakpoint-down(sm) {
        padding: 0 20px 20px;
    }
}

.box-buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $r2-border;

    .btn {
        width: 34%;
        padding: 10px 15px;
        text-align: center;
        border-right: 1px solid $r2-border;
        border-radius: 0;
        color: $r2-black;
        font-size: 12px;
        font-weight: bold;

        i {
            margin-right: 5px;
        }

        &:last-child {
            border-right: 0;
        }
    }
}

.box-table {
    padding: 24px;

    @include media-breakpoint-down(sm) {
        padding: 20px;
    }

    .dataTables_wrapper {
        margin: 0 -24px;

        @include media-breakpoint-down(sm) {
            margin: 0 -20px;
        }
    }
}

.box-map {

    .mapboxgl-canvas,
    > div {
        overflow: hidden;
        border-radius: $border-radius;
    }
}

.box-collapse {
    padding-top: 16px;
    padding-bottom: 30px;
}


.box-collapse-item {
    &:last-child {

        border-bottom: 1px solid $r2-border;
    }
}

.box-collapse-header {
    background: white;
    display: flex;
    padding: 18px 24px;
    border-top: 1px solid $r2-border;
    position: relative;
    cursor: pointer;

    h3 {
        margin: 0;
    }

    &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -12px;
        height: 24px;
        width: 20px;
        background: url("/images/icons/icon-chevron-down.svg") center center no-repeat;
        background-size: contain;
        transform: rotate(180deg);
        content: "";
    }

    &.collapsed:after {
        transform: rotate(0deg);
    }
}

.box-collapse-content {
    padding: 24px;
    border-top: 1px solid $r2-border;
    background: $r2-table-hover-bg;
}


.box-cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .js-collection-child {
        > .box-card {
            border-top: none;
            border-radius: 0;
        }
    }

    .js-collection-child:first-child {
        > .box-card {
            border-top: 1px solid $r2-border;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
    }

    .js-collection-child:last-child {
        > .box-card {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

.box-cards--grid {
    flex-wrap: wrap;
    margin: 0 -8px;
    flex-direction: row;

    .box-card {
        border-radius: $border-radius !important;
        border: 1px solid $r2-border !important;
        width: calc(50% - 16px);
        margin: 0 8px 16px;
    }
}

.box-cards--tabs {
    .box-card {
        margin-bottom: 8px;
        border: 1px solid $r2-border !important;
        border-radius: $border-radius !important;
    }
}

.box-card {
    border: 1px solid $r2-border;
    border-top: 0;
    width: 100%;
    position: relative;
    color: inherit;

    &:hover,
    &:focus {
        text-decoration: none;
        color: inherit;
    }

    &:first-child {
        border-top: 1px solid $r2-border;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    &.active {
        background: $r2-blue-10 !important;
        border-color: $r2-blue-10 !important;

        span {
            color: $r2-blue;
        }
    }
}


.box--login {
    min-width: 400px;

    .form-check-input {
        -webkit-appearance: checkbox;
    }
}

.box--offsetTop {
    margin-top: 8px;
}

.box--empty {
    background: $r2-table-hover-bg;
    border: 1px dashed $r2-border;
    text-align: center;
    margin-bottom: 15px;
}

.box--emptyNarrow {
    .box-content {
        padding: 5px 15px;
    }
}

.box--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.box-tabs {
    padding: 24px 24px 0 24px;
    border-bottom: 1px solid $r2-border;

    h2,
    h3 {
        width: 100%;
    }

    .nav-item {
        margin: 0 20px 0 0px;


        &:last-child {
            margin-right: 0;
        }
    }

    .nav-link {
        border-radius: 0;
        padding: 5px 2px 15px;
        font-size: 16px;
        font-weight: bold;
        color: $r2-purple;
        border: none;
        position: relative;

        &.disabled {
            color: $r2-purple-20;
            opacity: 1;
        }

        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            border-radius: 3px 3px 0 0;
            background: $r2-blue;
            content: "";
            display: none;
        }

        &.active {
            background: none;
            color: $r2-black;

            &:before {
                display: block;
            }
        }

        &:hover,
        &:focus {
            &:before {
                display: block;
            }
        }
    }
}

.box-tabs--second {

    .nav-item {
        margin-left: 0px;
    }

    .nav-link {
        font-size: 14px;
    }
}

.box-anchors {
    margin-bottom: 20px;

    .nav-item {
        margin: 0 20px 0 0;
    }

    .nav-link {
        padding: 5px 0;
        font-weight: bold;
        font-size: 18px;
    }
}

.box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 0;
    min-height: 58px;

    h1,
    h2,
    h3 {
        margin: 0;

        small {
            font-size: 14px;
            font-weight: normal;
            color: $r2-purple;
            display: block;
        }
    }

    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }
}

.box-header--outlined {
    border-bottom: 1px solid $r2-border;
    display: block;
    padding-bottom: 0;
}

.box-header--conversations {
    padding: 20px 16px 0;
}

.box-filters {

    padding: 24px 24px 0;
}

.box-filters-header {
    display: flex;
    justify-content: space-between;
}

.box-filters-mainFilters {
    display: flex;
}

.box-filters-mainFilter {
    .control-label {
        display: none;
    }

    .col-sm-10 {
        flex: none !important;
        max-width: 100% !important;

        > div {
            display: flex;
        }
    }

    .form-group {
        margin: 0;
    }

    .form-check {
        padding: 0;
        margin: 0;

        input {
            display: none;
        }

        label {
            padding: 0.4375rem 0.875rem;
            color: $r2-purple;
            font-weight: bold;
            border: 1px solid transparent;
            border-radius: $border-radius;
            cursor: pointer;

            &:before {
                top: 8px;
            }

            &:after {
                top: 14px !important;
            }
        }

        input:checked + label {
            border-color: $r2-border;
            color: $r2-black;
        }
    }

    .form-item--checkbox {
        .form-check label {
            padding-left: 30px;
        }

        input:checked + label {
            border-color: transparent;
        }
    }

    border-right: 2px solid $r2-border;

    &:last-child {
        border-right: 0 solid transparent !important;
    }
}


.box-filters-dropdown {
}

.box-filters-dropdown-subFilters {
    border: 1px solid $r2-border;
    padding: 15px;
    border-radius: $border-radius;
    margin-top: 15px;
}

.box-content {

    .box-table {
        margin: 0 -24px;
        padding: 0;

        .table-wrapper {
            padding: 0 24px
        }
    }
}

.box-content--inset {
    padding-top: 0;
    margin-top: -24px;
}

.box-content--divided {
    border-top: 1px solid $r2-border;
}

.box-content--narrowTop {
    padding-top: 8px;
}

.box-content-tools {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    .btn {
        margin-left: 2px;
    }
}

.box-actions {
    padding: 0 24px 24px;
}

.box-buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $r2-border;

    .btn {
        width: 34%;
        padding: 10px 15px;
        text-align: center;
        border-right: 1px solid $r2-border;
        border-radius: 0;
        color: $r2-black;
        font-size: 12px;
        font-weight: bold;

        i {
            margin-right: 5px;
        }

        &:last-child {
            border-right: 0;
        }
    }
}

.box-table {
    padding: 24px;

    .dataTables_wrapper {
        margin: 0 -24px;
    }
}

.box-map {

    .mapboxgl-canvas,
    > div {
        overflow: hidden;
        border-radius: $border-radius;
    }
}

.box-cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .js-collection-child {
        > .box-card {
            border-top: none;
            border-radius: 0;
        }
    }

    .js-collection-child:first-child {
        > .box-card {
            border-top: 1px solid $r2-border;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
    }

    .js-collection-child:last-child {
        > .box-card {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

.box-cards--tabs {
    .box-card {
        margin-bottom: 8px;
        border: 1px solid $r2-border !important;
        border-radius: $border-radius !important;
    }
}


.box-card {
    border: 1px solid $r2-border;
    border-top: 0;
    width: 100%;
    position: relative;
    color: inherit;

    &:hover,
    &:focus {
        text-decoration: none;
        color: inherit;
    }

    &:first-child {
        border-top: 1px solid $r2-border;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    &.active {
        background: $r2-blue-10 !important;
        border-color: $r2-blue-10 !important;

        span {
            color: $r2-blue;
        }
    }
}


.box-card--single {
    border-top: 1px solid $r2-border;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    margin-bottom: 5px;

    .form-item:last-child {
        //	margin-bottom: 0;
    }
}

.box-card--offsetTop {
    margin-top: 30px;
}

.box-card--offsetTopNarrow {
    margin-top: 16px;
}

.box-card--offsetBottom {
    margin-bottom: 30px;
}

.box-card--active {
    border-color: $r2-blue !important;
}

.box-card--warning {
    border-color: $r2-sys-orange !important;
}

.box-card--danger {
    border-color: $r2-sys-red !important;
}

.box-card--selected {
    background: $r2-purple-5 !important;
}

.box-card--toolSpacing {
    .box-card-content {
        padding-right: 50px;
    }
}

.box-card--offset {
    margin-bottom: 30px;
}

.box-card--gray {
    background: $r2-table-hover-bg;
}

.box-card--summary {
    background: $r2-table-hover-bg;
    border: 1px dashed $r2-border !important;
}

.box-card--withTable {
    border-top: 1px solid $r2-border;
    border-radius: 4px;
    overflow: hidden;

    .box-card-content {
        background: $r2-table-hover-bg;
        padding: 0 24px 16px;
    }

    tr {
        background: white;
    }

    .box-card-header {
        padding: 12px 24px;
        border-bottom: 1px solid $r2-border;

        h2 {
            font-size: 22px;
            margin: 0;
        }
    }

    .table {
        margin-bottom: 0;
    }

    .table thead tr td,
    .table thead tr th {
        padding-bottom: 0;
    }
}

.box-card--dashed {
    border-style: dashed !important;
}

.box-card--withCollapse {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-width: 0 !important;
}

.box-card--noOverlow {
    overflow: hidden;
}

.box-card--mutated {
    padding-right: 60px;
    position: relative;

    &:after {
        position: absolute;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        content: fa-content($fa-var-long-arrow-right);
        top: 50%;
        right: 30px;
        margin-top: -10px;
        font-size: 26px;
        color: $r2-blue;
    }
}

.box-card-content {
    padding: 15px;

    p {
        margin-bottom: 5px;
    }

}

.box-card-content--noPaddingBottom {
    padding-bottom: 0;
}

.box-card-content--gray {
    background: $r2-table-hover-bg;

    .box-card {
        background: white;
    }
}


.box-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box-card-header--large {
    padding-top: 10px;
    padding-bottom: 10px;
}

.box-card-header--gray {
    background: $r2-table-hover-bg;
    border-bottom: 1px solid $r2-border;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.box-card-header--collapsable {
    display: flex;
    padding: 10px 15px 14px;
    color: $body-color;
    text-decoration: none;
    position: relative;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $body-color;
    }

    h3 {
        margin: 0;
    }

    &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -12px;
        height: 24px;
        width: 20px;
        background: url("/images/icons/icon-chevron-down.svg") center center no-repeat;
        background-size: contain;
        transform: rotate(180deg);
        content: "";
    }

    &.collapsed:after {
        transform: rotate(0deg);
    }
}

.box-card-header-title {
    padding: 12px 15px;

    h3 {
        margin: 0;
    }
}

.box-card-header-tools {
    padding: 12px 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.box-card-header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.box-card-status {
    position: absolute;
    top: -12px;
    left: -12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.box-card-status--success {
    background: $r2-sys-green;
}

.box-card-list {
    display: flex;
    flex-direction: column;
}

.box-card-list-item {
    position: relative;
    margin-bottom: 12px;
    color: $r2-purple;

    b,
    strong {
        color: $r2-black;
    }

    &:last-child {
        margin: 0;
    }
}

.box-card-list-item--withIcon {
    padding-left: 26px;
}

.box-card-list-item-icon {
    position: absolute;
    top: 4px;
    left: 0;
}

.box-card-content-columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -24px;
}

.box-card-content-columns--spaced {
    margin-bottom: 0;
}

.box-card-content-column {
    width: 33.33%;
    margin-bottom: 24px;
    padding: 0 15px;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    h2 {
        margin-bottom: 15px;
    }

    .box-product-header-teaser--large {
        margin-top: -10px;
    }
}

.box-card-content-column--half {
    width: 50%;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.box-card-columns {
    display: flex;
    padding: 12px;
    justify-content: space-between;
}

.box-card-column {
    padding: 12px;
    width: 20%;
}

.box-card-tools {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
}

.box-card-tool {
    color: $r2-purple;
    margin-left: 5px;
    display: block;
    -webkit-appearance: none;
    background: none;
    border: none;
}

.box-card-tool--delete {
    color: $r2-sys-red;
}

.box-card-actions {
    padding: 6px 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-card-action {
    flex: 1 0 0;
    margin: 0 3px;
    width: 50%;
}

.box-card-product {
    background: white;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 24px 70px 24px 24px;
}

.box-card-product-teaser {
    display: flex;
}

.box-card-product-teaser-image {
    width: 66px;
    margin-right: 24px;
}

.box-card-product-teaser-content {
    h2 {
        margin: 0;
        font-size: 16px;
    }
}

.box-card-product-price {
    font-size: 16px;
    text-align: right;
    font-weight: bold;

    small {
        font-weight: normal;
        display: block;
        font-size: 14px;
    }
}

.box-card-product-edit {
    position: absolute;
    top: 10px;
    right: 10px;
}


.box-totals {
    margin-left: 60%;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;

    dt {
        width: 75%;
        margin-bottom: 10px;
        font-weight: normal;
    }

    dd {
        font-weight: normal;
        width: 25%;
        text-align: right;
        margin-bottom: 10px;
    }

    b,
    strong {
        font-size: 16px;
    }
}

.box-products {
    padding: 16px 0;
}

.box-products-message {
    padding: 0 24px;
}

.box-product-wrapper {
    margin-top: -1px;

    &:first-child {
        margin-top: 0;
    }
}

.box-product {
}

.box-product--linked {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.box-product-header {
    padding: 20px 50px 20px 24px;
    border-top: 1px solid $r2-border;
    border-bottom: 1px solid $r2-border;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:before {
        position: absolute;
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
        border-radius: 0 4px 4px 0;
        z-index: 1;
        display: none;
    }
}


.box-product-header--success {
    &:before {
        background: $r2-sys-green;
        display: block;
    }
}

.box-product-header--warning {
    &:before {
        background: $r2-sys-red;
        display: block;
    }
}

.box-product-header-link {
    display: block;
    cursor: pointer;
    text-decoration: none !important;
    color: inherit;
}

.box-product-header-teaser {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.box-product-header-teaser--large {
    align-items: center;
}

.box-product-image {
    width: 36px;
    height: 36px;
    border: 1px solid $r2-border;
    border-radius: 2px;
    padding: 2px;
    display: flex;
    margin-right: 10px;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
    }
}

.box-product-image--large {
    width: 60px;
    height: 60px;
    border-radius: $border-radius;
    margin-right: 20px;

    .tag-image {
        width: 50px;
        height: 50px;
    }
}

.box-product-name-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.box-product-name {
    font-weight: bold;
    line-height: 1.3;
}


.box-product-type {
    font-weight: normal;
    color: $r2-purple;
    line-height: 1.3;
}

.box-product-toggle {
    position: absolute;
    top: 50%;
    right: 24px;
    margin-top: -10px;
    transition: all 0.3s ease;

    &[aria-expanded="true"] {
        transform: rotate(180deg);
    }
}

.box-product-edit {
    position: absolute;
    top: 50%;
    right: 42px;
    margin-top: -11px;
    background: none;
    border: none;
    color: $r2-blue;
}

.box-product-info {
    display: flex;
    flex-direction: column;
}

.box-product-info-item {
    margin-top: 5px;
    margin-bottom: 5px;
    color: $r2-blue;

    span {
        color: $r2-black;
    }

}

.box-product-content {
    background: $r2-table-hover-bg;
    padding: 10px 24px;

    .form-item--compact {
        .control-label {
            background: $r2-table-hover-bg;
        }
    }
}

.box-columns {
    display: flex;
    flex-wrap: wrap;
    margin: 24px -24px -24px;
    border-top: 1px solid $r2-border;
}

.box-column {
    padding: 24px;
    border-right: 1px solid $r2-border;
    width: 50%;

    &:last-child {
        border-right: none;
    }
}

.box-grid {
    display: flex;
    // flex-wrap: wrap;
    padding: 24px 12px;
}

.box-grid-col {
    width: 25%;
    padding: 0 12px;
}

.box-grid-col--aside {
    width: 18%;
    min-width: 250px;
}

.box-grid-col--content {
    width: 82%;
}

.box-breadcrumbs {
    display: flex;
    flex-wrap: wrap;
}

.box-breadcrumbs-link,
.box-breadcrumbs-text {
    font-weight: bold;
    font-size: 18px;
    color: $r2-purple;
    position: relative;
    padding-right: 27px;
    margin-right: 15px;

    &:after {
        position: absolute;
        top: 5px;
        right: 0;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        content: fa-content($fa-var-chevron-right);
    }

    &:last-child {
        color: $body-color;

        &:after {
            display: none;
        }
    }
}

// Inbox

.box-messages {
    min-height: calc(100vh - 102px);
    display: flex;
    flex-direction: column;
}

.box-messages-content {
    flex: 1;
    display: flex;
}

.box-messages-overview {
    width: 56%;
    padding: 24px;
}

.box-messages-conversations {
    width: 44%;
    border-left: 1px solid $r2-border;
    position: relative;

    .inbox {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

