h1 {
    font-size: 20px;
}

h2 {
    font-size: 18px;

    small {
        font-size: 16px;
        color: $r2-purple;
        font-weight: bold;
    }
}

h3 {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 30px;

    &:first-child {
        margin-top: 0;
    }

    small {
	    font-size: 14px;
	    color: $r2-purple;
	    font-weight: normal;
	    margin-top: 5px;
    }

    span {
	    display: block;
	    font-size: 14px;
	    font-weight: normal;
	    margin-top: 5px;
    }
}

.h3--offsetTop {
    margin-top: 30px !important;
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

.noWrap {
    white-space: nowrap;
}

.color--blue {
	color: $r2-blue;
}

.color--light {
	color: $r2-purple;
}

.text--spaced {
	margin: 15px 0 10px;
	font-weight: bold;
}
