.grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 0px;
}

.grid--offset {
    margin-top: 30px;
}

.grid-column {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
    display: flex;
}

.grid-column--large {
    width: 66%;
}

.grid-column--small {
    width: 34%;
}