.notifications {
	margin-bottom: 15px;
    overflow-x: hidden;
}

.notification {
    background: $r2-table-hover-bg;
    padding: 15px 20px;
    border-radius: $border-radius;
    margin-bottom: 7px;
    position: relative;
    word-break: break-word;
}

.notification--danger {
    background: $r2-sys-red-bg;
    color: $r2-sys-red;
}

.notification--create {
    padding: 0;
}

.notification--withTools {
    .notification-header {
        padding-right: 20px;
    }
}

.notification--contact {
    background: $r2-purple-5;
}

	.notification-tools {
	    position: absolute;
	    top: 12px;
	    right: 12px;
	    display: flex;
	}
		
		.notification-tool {
		    background: none;
		    border: none;
		    color: $r2-purple;
		    margin-left: 3px;
		    font-size: 12px;
		}
	
	.notification-header {
	    color: $r2-purple;
	    font-size: 12px;
	    margin-bottom: 5px;
	}
	
		.notification-header-source {
		    color: $r2-blue;
		}

	.notification-content {
	    font-weight: bold;
	    line-height: 1.4;
	}

	.notification-message {
	    line-height: 1.4;
	}

.notification-footer {
    color: $r2-blue;
    font-size: 12px;
    margin-top: 5px;
}

.notification-create {

}
	
	.notification-create-control {
	    padding: 10px 20px;
	    background: none;
	    width: 100%;
	    border: none;
	    display: block;
	    border-bottom: 1px solid $r2-border;
	}

		.notification-create-control:focus {
		    outline: none;
		}
		
		.notification-create-control::placeholder {
		    color: $r2-purple;
		}

	.notification-create-select {
	    display: flex;
	    justify-content: space-between;
	    border-bottom: 1px solid $r2-border;
	}
		
		.notification-create-select-label {
		    padding: 10px 20px;
		    color: $r2-purple;
		    width: 33%;
		    margin: 0;
		}

		.notification-create-select-control {
		    -webkit-appearance: none;
		    padding: 10px 40px 10px 20px;
		    border: none;
		    border-radius: 0;
		    background: url("/images/icons/icon-chevron-down.svg") right center no-repeat;
		    background-position: calc(100% - 10px) center;
		    background-size: 12px;
		}
			
			.notification-create-select-control:focus {
			    outline: none;
			}

	.notification-create-tools {
	    display: flex;
	    justify-content: flex-end;
	    padding: 5px 20px 12px;
	
	    .btn {
	        margin-left: 3px;
	    }
	}
