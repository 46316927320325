.alert {
    padding: 6px 12px;
    border-radius: 4px;

    &:last-child {
        margin-bottom: 0;
    }

    p,
    ul {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .tag {
        background: white;
    }

    .summary {
        margin-bottom: 0;
    }

    .summary-item-subtitle {
        color: inherit;
    }
}

.alert-info {
    color: #126cad;
    background-color: $r2-blue-20;
    border-color: $r2-blue-30;
}

.alert-success {
    color: #098643;
    background-color: $r2-sys-green-bg;
    border-color: #a2ddbd;
}

a.alert {
	display: block;

	&:hover,
	&:focus {
		text-decoration: none;
		color: white;
	}
}

		.alert--top {
			z-index: 1000;
			border-radius: 0px;
		}

		.alert--warning {
		    background: $r2-sys-orange-bg;
		    color: $r2-sys-orange !important;
		    border-color: transparent;
		}

		.alert--orange {
		    background: $r2-sys-orange;
		    color: white;
		    text-align: center;
		    align-items: center;
		}
		.alert--danger {
		    background: $r2-sys-red;
		    color: white;
		    text-align: center;
		    align-items: center;
		}

		.alert--success {
		    background: $r2-sys-green;
		    color: white;
		    text-align: center;
		    align-items: center;
		}

		.alert--large {
		    padding: 24px;
		    width: 100%;
		}

		.alert--withAction {
			position: relative;
			text-align: left;
			padding-right: 50px;
		}

			.alert-action {
				position: absolute;
				top: 50%;
				margin-top: -10px;
				right: 10px;

				a,
				a:hover,
				a:focus {
					color: white;
				}
			}
