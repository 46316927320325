.webshops {
    display: flex;
    margin: 0 -12px;
    flex-wrap: wrap;
}

.webshop {
    width: 33.33%;
    padding: 0 12px;
    margin-bottom: 24px;
    cursor: pointer;

    input:checked + .webshop-box {
        border-color: $r2-blue;
    }

    input {
        display: none;
    }
}

	.webshop-order {}

		.webshop-order-actions {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -5px;

			.btn {
				width: auto;
				display: inline-block;
				margin: 0 5px;

				@include media-breakpoint-down(md) {
					display: block;
					width: 100%;
					padding: 14px 24px;
				}

				& + .btn {
					@include media-breakpoint-down(md) {
						margin-top: 10px;
					}
				}
			}
		}

			.webshop-order-actions-message {
				width: 100%;
				padding: 10px 5px;

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}

.webshop-box {
    border: 1px solid $r2-border;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius;
}

.webshop-image {
    width: 50px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: $border-radius;
    border: 1px solid $r2-border;

    img {
        max-width: 100%;
        max-height: 100%;
        zoom: 1;
    }
}

.order-table-info {
    white-space: nowrap;
    font-weight: bold;
    line-height: 1.3;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-table-details {
    white-space: nowrap;
    color: $r2-purple;
    line-height: 1.3;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.supplier-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 15px;
    align-items: center;
}

.order-header {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;

	> * {
		margin-right: 10px;
	}
}

	.order-header-pickup {
		position: relative;
		padding-left: 24px;
		color: #696F6A;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

		.order-header-pickup-icon {
			position: absolute;
			top: 3px;
			left: 0;
			width: 16px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
			}

			.far,
			.fal,
			.fas,
			.fa {
				font-size: 21px;
			}
		}

.order-period {
    @extend .box;
    position: relative;
    padding-left: 130px;
    padding-right: 220px;
    min-height: 76px;
    padding-top: 35px;

    @include media-breakpoint-down(sm) {
	    padding: 24px 24px 0 24px;
	    border-radius: 0;
	    border-left: none !important;
	    border-right: none !important;
    }
}

	.order-period-label {
	    position: absolute;
	    top: 0; left: 0;
	    padding: 15px;
	    height: 100%;
	    display: flex;
	    align-items: center;
	    font-size: 12px;
	    color: $r2-purple;

	    span {
	        display: flex;
	        align-items: center;
	    }

	    @include media-breakpoint-down(sm) {
		    display: none;
	    }
	}

		.order-period-label-icon:first-child {
		    border-top: 1px solid $r2-border;
		    width: 7px;
		    margin-right: 5px;
		    position: relative;
		    margin-top: 1px;

		    &:before {
		        position: absolute;
		        left: 0;
		        top: -4px;
		        height: 7px;
		        width: 1px;
		        background: $r2-border;
		        content: "";
		    }
		}

		.order-period-label-icon:last-child {
		    border-top: 1px solid $r2-border;
		    width: 7px;
		    margin-left: 5px;
		    position: relative;
		    margin-top: 1px;

		    &:before {
		        position: absolute;
		        right: 0;
		        top: -4px;
		        height: 7px;
		        width: 1px;
		        background: $r2-border;
		        content: "";
		    }
		}

	.order-period-product {
	    position: absolute;
	    top: 0; right: 0;
	    width: 220px;
	    padding: 15px;
	    display: flex;
	    align-items: center;
	    height: 100%;

	    .tag {
	        margin-right: 5px;
	    }

	    @include media-breakpoint-down(sm) {
		    display: none;
	    }
	}

.order-period-progress {
    background: $r2-purple-20;
    height: 6px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 4px;
    position: relative;
}

.order-period-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $r2-sys-green-light;
    border-radius: 3px;
    z-index: 1;
}

.order-period-progress-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $r2-blue;
    border: 4px solid $r2-blue-20;
    position: absolute;
    margin-left: -8px;
    margin-top: -5px;
    z-index: 2;
}

.order-period-progress-labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: $r2-purple;
}

.supplier-header-name {
    color: $r2-purple;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.supplier-header-image {
    width: 50px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: $border-radius;
    border: 1px solid $r2-border;

    img {
        max-width: 100%;
        max-height: 100%;
        zoom: 1;
    }
}

.supplier-header-tools {
    align-self: flex-end;
}

.supplier-info {
    display: flex;
    flex-direction: column;
}

.supplier-info-item {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    position: relative;
}

.supplier-info-item-icon {
    position: absolute;
    top: auto;
    left: 0;
    color: $r2-blue;
    font-size: 12px;
}

.supplier-info-item-col {
    padding-left: 10px;

    &:last-child {
        text-align: right;
    }

    span {
        color: $r2-blue;
    }
}
