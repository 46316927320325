.documents {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
}

	.document {
		padding: 0 5px;
		margin-bottom: 10px;
	}
	
		.document-box {
			border-radius: $border-radius;
			border: 1px solid $r2-border;
		}
		
				.document-box--image {
					display: flex;
					max-width: 120px;
					height: 60px;
					justify-content: center;
					align-items: center;
					
					img {
						zoom: 1;
						max-width: 100%;
						max-height: 100%;
					}
				}
				
				.document-box--file {
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				
			.document-link {
				padding: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				color: $r2-purple;
				
				&:hover,
				&:focus {
					text-decoration: none;
					color: $r2-purple;
				}
			}
			
				.document-link-icon {
					font-size: 1.2rem;
					line-height: 1;
				}
				
				.document-link-title {
					max-width: 120px;
					white-space: nowrap; 
					overflow: hidden;
					text-overflow: ellipsis;
				}
				
	.document-add {
		border-radius: $border-radius;
		border: 1px solid $r2-border;
		height: 60px;
		width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $r2-purple;
		font-size: 1.2rem;
	}