.navigation-wrapper--minimal {
	a:hover,
	a:focus {
		text-decoration: none;
	}
}

.portal-navigation {
	padding: 0 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
	@include media-breakpoint-down(sm) {
		padding: 5px 15px;
	}
	
	.navbar-toggler {
		font-size: 16px;
		align-self: flex-end;
		right: 0;
		line-height: 1.6;
	}

    .navbar-collapse {
	    justify-content: space-between;
	    flex-wrap: wrap;
    }
    
    .nav {
	    @include media-breakpoint-down(sm) {
		    flex-direction: column;
	    }
    }

    .nav-item {
        margin: 6px 0;
    }
    
	    	.nav-item--text {
		    	font-weight: bold;
		        color: $r2-purple;
		        color: var(--quaternary);
		        padding: 13px 15px 13px 15px;
		        
		        @include media-breakpoint-down(sm) {
			    	padding: 5px 15px;   
		        }
	    	}

    .nav-item:focus {
        outline: none;
    }

    .nav-link {
        position: relative;
        font-weight: bold;
        border-radius: 4px;
        color: $r2-purple;
        color: var(--quaternary);
        padding: 13px 15px 13px 15px;
        
        @include media-breakpoint-down(sm) {
	    	padding: 5px 15px;   
        }

        &:hover,
        &:focus,
        &.active {
            background: none;
            color: $r2-black;
            color: var(--primary);

            .nav-icon {
                color: $r2-blue;
                color: var(--primary);

                svg {
                    fill: $r2-blue;
                    fill: var(--primary);
                }
            }
        }
    }

    .nav-link:focus {
        outline: none;
    }

    .nav-icon {
        position: absolute;
        top: 50%;
        left: 14px;
        margin-top: -14px;
        width: 20px;
        height: 20px;

        svg {
            fill: $r2-purple;
            max-height: 100%;
        }
    }

    .nav-text {
        margin-left: 36px;
    }

    .nav-icon-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        top: -2px;
    }

    .nav-treeview {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        padding-top: 1px;
    }

    .treeview-item {
        padding-left: 40px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .treeview-link {
        display: block;
        padding: 4px 10px 4px 16px;
        color: $r2-purple-40;
        border-radius: 4px;
        overflow: hidden;
        max-width: 0;
        max-height: 52px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: max-width 0.3s ease;

        &:hover,
        &:focus {
            text-decoration: none;
            background: $r2-blue-10;
            color: black;

        }
    }

    .has-treeview--active {
        .nav-treeview {
            max-height: 1000px;
        }
    }

    .dropdown-menu {
	    min-width: 233px;
    }
}

		.portal-navigation--desktop {
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		
.portal-navigation {
    width: 100%;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.08); 
}

		.portal-navigation--mobile {
			display: none;
			transform: translateY(-100%);
			transition: all 0.3s ease-in-out;
			
			@include media-breakpoint-down(sm) {
				display: block;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $r2-bg;
				z-index: 50;
				padding: 60px 0 0 !important;	
				
			}
			
			.nav {
				width: 100%;
			}
			
			.nav-item {
				margin: 0 !important;
			}
			
			.nav-link {
				background: white;
				min-height: 75px;
				padding: 10px 24px;
				border-bottom: 1px solid $r2-border;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				border-radius: 0;
				font-size: 18px;
				
				&:hover,
				&:focus {
					background: white;
				}
				
				.nav-icon {
					left: 24px;
					width: 24px;
					height: 24px;
					margin-top: -16px;
				}
				
				.nav-text {
					margin-left: 42px;
				}
			}
		}
		
body.body--portalNavActive {
	.portal-navigation--mobile {
		transform: translateY(0);
	}
}
		
.portal-mobileNavigation {
	display: none;
	
	@include media-breakpoint-down(sm) {
		display: block;
	}

	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: white;
	border-radius: $border-radius $border-radius 0 0;
	box-shadow: 0 -3px 5px 6px rgba(0,0,0,0.03);
	
	.nav {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
		padding: 11px 0 16px;
		
		.nav-item {
			.nav-link {
				display: block;
				width: 55px;
				height: 55px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				border-radius: $border-radius;
				margin: 0 4px;
				
				&:before {
					position: absolute;
					top: -11px;
					width: 100%;
					height: 3px;
					content: "";
					border-radius: 0 0 3px 3px;
					background: none;
				}
				
				.nav-icon {
					width: 20px;
					
					svg {
						fill: $r2-purple;
					}
				}
			}
			
			&.active > .nav-link,
			.nav-link:hover,
			.nav-link:focus {
				background: $r2-blue-10;
				color: $r2-blue;
				
				.nav-icon svg {
					fill: $r2-blue;
				}
				
				&:before {
					background: $r2-blue;
				}
				
			}
		}
	}
}