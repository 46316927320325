// Body

$body-bg: var(--bg-white);
$r2-primary: var(--primary);
$r2-secondary: var(--secondary);
$r2-text: var(--color-text);
$r2-tertiary: var(--tertiary);

//$primary: #FFDD02;
@function lightness($color, $lightnessMultiplier) {
    $color: str-replace($color, 'var(');
    $color: str-replace($color, ')');
    $color-h: var(#{$color+'-h'});
    $color-s: var(#{$color+'-s'});
    $color-l: var(#{$color+'-l'});
    @return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightnessMultiplier}));
}

$component-active-bg: var(--primary);
$input-focus-border-color: var(--primary);
$custom-control-indicator-active-bg: var(--primary);
$custom-range-thumb-active-bg: var(--primary);
$link-color: var(--primary);
$link-hover-color: var(--primary);

$container-max-widths: (
    xl: 1430px
);

$gray-100: #F9F9F8;
$gray-200: #F1F1ED;
$gray-250: #E9E9E4;
$gray-300: #DBE0E5;
$gray-500: #CDD4DA;
$gray-800: #676F69;
$gray-850: #495057;
$gray-900: #333333;

@import 'variables';

// Typography
$font-family-sans-serif: 'PT Sans', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #EE143E;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #21AA33;
$teal: #4dc0b5;
$cyan: #6cb2eb;

