.tags {
	display: flex;
	flex-wrap: wrap;

	.tag {
		margin: 0 5px 10px 0;
	}
}

.tag {
    border-radius: $border-radius;
    border: 1px solid $r2-border;
    padding: 6px 12px;
    // white-space: nowrap;
    display: inline-block;
    min-height: 36px;
    color: $r2-purple;
    text-decoration: none;
}
        .tag-sm {
            padding: 0.4375rem 0.875rem;
            font-size: 0.765625rem;
            border-radius: 0.2rem;
            line-height: 1.5;
            min-height: 34px;
        }

		.tag--light {
		    background: $r2-purple-5;
		    border-color: transparent;
		    color: $r2-purple;
		}

		.tag--danger {
		    background: $r2-sys-red-bg;
		    color: $r2-sys-red;
		    border-color: transparent;
		}

		.tag--warning {
		    background: $r2-sys-orange-bg;
		    color: $r2-sys-orange;
		    border-color: transparent;
		}

		.tag--success {
		    background: $r2-sys-green-bg;
		    color: $r2-sys-green;
		    border-color: transparent;
		}

		.tag--withImage {
		    padding: 6px 5px 0px;

		    img {
		        height: 18px;
		        width: auto !important;
		    }
		}

		.tag--outlinedPrimary {
			border-color: $r2-blue;
			color: $r2-black;
		}

		.tag--inactive {
			background: $r2-table-hover-bg;
			color: $r2-black;
		}


		.tag--square {
		    width: 36px;
		    min-width: 36px;
		    height: 36px;
		    margin-right: 10px;
		    padding: 8px 5px 0;

		    .tag-image {

		    }
		}

		.tag--withIcon {
			padding-top: 6px;
		}

		.tag--offset {
		    margin-bottom: 15px
		}

		.tag--noWrap {
			white-space: nowrap;
		}

		.tag--large {
			padding: 8px 12px;
			min-height: 40px;
		}

		.tag--withImageFull {
			padding: 0;

			.tag-image {
				width: 34px;
			    height: 34px;
			}
		}


.tag-image {
    width: 28px;
    height: 22px;
    display: inline-block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.tag-icon {
	text-align: center;

    &:after {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        content: fa-content($fa-var-question-circle);
    }
}

		.tag-icon--checkout {
		    &:after {
		        content: fa-content($fa-var-shopping-cart);
		    }
		}
        .tag-icon--boxes {
		    &:after {
		        content: fa-content($fa-var-boxes-alt);
		    }
		}

		.tag-icon--phone {
		    &:after {
		        content: fa-content($fa-var-phone);
		    }
		}

		.tag-icon--api {
		    &:after {
		        content: fa-content($fa-var-ethernet);
		    }
		}

        .tag-icon--ivr {
            &:after {
                content: fa-content($fa-var-voicemail);
            }
        }

        .tag-icon--user-secret {
		    &:after {
		        content: fa-content($fa-var-user-secret);
		    }
		}
        .tag-icon--clock {
		    &:after {
		        content: fa-content($fa-var-clock);
		    }
		}

		.tag-icon--euro {
		    &:after {
		        content: fa-content($fa-var-euro-sign);
		    }
		}

		.tag-icon--location {
		    &:after {
		        content: fa-content($fa-var-map-marker-alt);
		    }
		}

.discountLabel {
	min-height: 20px;
	line-height: 20px;
	padding: 0 10px;
	border-radius: 10px;
	background: $r2-blue;
	color: white;
	font-size: 12px;
	display: inline-block;
	position: relative;
	top: -1px;
}
