.table-wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.clickable-row {
	cursor: pointer;
}

.table {
    position: relative;
    border-spacing: 0 8px !important;
    border-collapse: separate;

    &:before {
        position: absolute;
        top: 0;
        left: -24px;
        right: -24px;
        height: 48px;
        background: $r2-table-header;
        content: "";
        z-index: 1;
    }

    thead {
        background: $r2-table-header;

        tr {

            td,
            th {
                color: $r2-purple;
                font-size: 12px;
                position: relative;
                z-index: 2;
                border: none;
                padding: 6px 10px 13px;

                &:first-child {
                    padding-left: 16px;
                }

                &:last-child {
                    padding-right: 16px;
                    text-align: right;
                }
            }


        }

    }

    tbody {

        tr:hover {
            background: $r2-table-hover-bg;
        }

        th,
        td {
            padding: 12px 10px;
        }

        td {
            vertical-align: middle;
            border-top: 1px solid $r2-border;
            border-bottom: 1px solid $r2-border;

            &:first-child {
                border-left: 1px solid $r2-border;
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                padding-left: 16px;
            }

            &:last-child {
                border-right: 1px solid $r2-border;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                padding-right: 16px;
                text-align: right;

                &:first-child {
                    text-align: center;
                }
            }
        }

        tr.totals {
            td {
                border: none;
                border-radius: 0 !important;
                padding-top: 0;
                padding-bottom: 0;
            }

            &:hover {
                background: none;
            }
        }

        tr.totals--first {
            td {
                //border-top: 1px solid $r2-border;
                padding-top: 15px;
            }
        }

        
        tr.row--prioHigh {
			td {
				border-color: $r2-sys-red;
			}
		}
		
		tr.row--prioMedium {
			td {
				border-color: $r2-sys-orange;
			}
			
		}
		
		tr.row--prioLow {
			td {
				border-color: $r2-sys-green;
			}
			
		}
    }
    
    tfoot {
	    td {
		    border-top: none;
	    }
    }
    
    .form-item {
	    margin: 0;
    }
}

		.table--noHeader {
			margin-top: -8px;
			
		    &:before {
		        display: none;
		    }
		}
		
		.table--selection {
			padding-left: 29px;
			
			td {
				position: relative;
			}
		}
		
			.table-selection-input {
				position: absolute;
				left: -30px;
				top: 50%;
				margin-top: -10px;
			}
			
			
.box-card-content .table {
	&:before {
        left: -15px;
        right: -15px;
    }
}

.modal-content {
    .table {

        &:before {
            left: -30px;
            right: -30px;
        }
    }
}

.table--noHeader {
    &:before {
        display: none;
    }
}

.table--noHeaderMobile {
	@include media-breakpoint-down(md) {
		&:before,
		thead {
	        display: none;
	    }
	}
}


.tr--selected {
    background: $r2-purple-5;
}

.td--bold {
    font-weight: bold;
}

.td--hiddenMobile {
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.dataTable-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 24px;
}


table.dataTable {
    thead {
        .sorting {
            background: none;

            &:after {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                content: fa-content($fa-var-sort-alt);
                margin-left: 10px;
                opacity: 0.2;
                width: 12px;
            }

            &:hover,
            &:focus {
                &:after {
                    opacity: 1;
                    content: fa-content($fa-var-sort-alpha-down);
                }
            }
        }

        .sorting_asc {
            background: none;

            &:after {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                content: fa-content($fa-var-sort-alpha-down);
                margin-left: 10px;
                width: 12px;
            }
        }

        .sorting_desc {
            background: none;

            &:after {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                content: fa-content($fa-var-sort-alpha-up);
                margin-left: 10px;
                width: 12px;
            }
        }
    }

    th {
        white-space: nowrap;
    }

    border-bottom: none !important;
    margin-bottom: 15px;

    tbody {
        th,
        td {
            padding: 12px 10px;

            span {
                line-height: 1.3;
                display: inline-block;
            }
        }
    }
}

.dataTables_wrapper {
    .dataTables_length {
        font-size: 12px;
        color: $r2-purple-40;
        padding-left: 24px;

        select {
            -webkit-appearance: none;
            margin: 0 10px;
            border: 1px solid $r2-border;
            border-radius: $border-radius;
            height: 40px;
            padding: 8px 40px 8px 15px;
            font-size: $font-size-base;
            font-weight: bold;
            text-align: center;
            background: url("/images/icons/icon-chevron-down.svg") right center no-repeat;
            background-position: calc(100% - 10px) center;
            background-size: 12px;

        }
    }

    .dataTables_filter {
        margin-bottom: 16px;
        padding-right: 24px;

        > label {
            border: 1px solid $r2-border;
            border-radius: $border-radius;
            height: 40px;
            padding: 0 0 0 15px;
            display: flex;
            align-items: center;
            font-weight: bold;
            margin: 0;
        }

        input {
            border: none;
            height: 38px;
            box-shadow: none;
            border-radius: $border-radius;
        }

        input:focus {
            outline: none;
        }
    }

    .dataTables_info {
        font-size: 12px;
        color: $r2-purple-40;
        padding-top: 13px;
        padding-left: 24px;
    }

    .dataTables_paginate {
        padding-right: 24px;

        .paginate_button {
            color: $r2-purple !important;
            border-radius: $border-radius;


            &.prev,
            &.next {
                background: $r2-purple-5 !important;
            }

            &:hover,
            &:focus {
                background: $r2-purple !important;
                border-color: transparent !important;
                color: white !important;
            }

            &.current {
                border: 1px solid $r2-border !important;
                color: $r2-black;
                background: none !important;
            }

            &.disabled {
                background: none !important;
                opacity: 0.5;

            }
        }
    }
}

.table-actions {
    display: flex;
    justify-content: flex-end;

    .dropdown {
        margin-left: 5px;
    }

    .btn {
        margin-left: 5px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.table-add {
    border: 1px dashed $r2-border;
    color: $r2-purple;
    padding: 12px 16px;
    border-radius: $border-radius;
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.table-brand {
	height: 36px;
	width: 72px;
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;
}

// Collapse table on mobile

@include media-breakpoint-down(sm) {
	.table--collapseMobile {
		&:before,
		thead {
			display: none;
		}
		
		tbody,
		tr,
		td {
			display: block;	
		}
		
		.table-cardRow, 
		tr.clickable-row {
			border: 1px solid $r2-border;
			border-radius: $border-radius;
			padding: 12px 10px;
			margin-bottom: 10px;
			background: white;
		}
		
		td {
			border-radius: 0 !important;
			border: none !important;
			padding: 0 !important;
		}
		
		td[data-header] {
			&:before {
				content: attr(data-header);
				display: inline-block;
				min-width: 150px;
				color: $r2-purple;
			}
		}
	}
}