.status {
    position: relative;
    margin-bottom: 5px;

    small {
        display: block;
        margin-top: 5px;
        line-height: 1.3;
        color: $r2-purple;
    }
}

.status--withIcon {
    padding-left: 24px;
}

.status-icon {
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
    }

    .far,
    .fal,
    .fas,
    .fa {
        font-size: 21px;
    }
}

