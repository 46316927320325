.field--radio-label {
    margin-bottom: 0;
    border-bottom-width: 1px !important;

    .r2-card-methode {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .row {
        width: 100%;
        min-height: 22px;

        .col-9 {
            @extend .pl-0, .d-flex, .align-items-center;
        }
    }

    &[for="online_payment"] .r2-card-methode {
        background-image: url('/images/logo/ideal.jpg');
    }

    &[for="visa"] .r2-card-methode {
        background-image: url('/images/logo/visa.png');
    }

    &[for="master_card"] .r2-card-methode {
        background-image: url('/images/logo/mastercard.png');
    }

    &[for="bank"] .r2-card-methode {
        background-image: url('/images/logo/overboeking.png');
    }

    &[for="card"] .r2-card-methode {
        background-image: url('/images/logo/card.gif');
    }

    &[for="cash"] .r2-card-methode {
        background-image: url('/images/logo/cash.png');
    }
}