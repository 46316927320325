.customerCard {
    @extend .box;
    position: relative;
    padding: 24px 24px 14px 76px;
    
    @include media-breakpoint-down(sm) {
	    display: none;
	    
	    & + .boxGrid--offsetTop {
		    margin-top: 0 !important;
	    }
    }
}

		.customerCard--empty {
		    padding: 24px 24px 9px 24px;
		}
		
		.customerCard--offset {
			margin-bottom: 30px;
		}

.customerCard-brand {
    border-radius: 8px;
   // border: 1px solid #ececf2;
    padding: 0;
    white-space: nowrap;
    display: inline-block;
    min-height: 36px;
    color: #8181A5;
    position: absolute;
    top: 24px;
    left: 24px;
    overflow: hidden;
}

	.customerCard-brand-logo {
	    width: 36px;
	    height: 36px;
	    display: block;
	    background-size: contain;
	    background-position: center center;
	    background-repeat: no-repeat;
	    border-radius: 8px;
	}

.customerCard-tools {
    position: absolute;
    top: 20px;
    right: 20px;
}

.customerCard-tool {
    color: $r2-purple;

}

.customerCard-header {
    margin-bottom: 10px;
}

.customerCard-header-title {
    margin-bottom: 2px;
}

.customerCard-header-details {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.customerCard-header-detail {
    color: $r2-purple;
    margin-right: 15px;
    white-space: nowrap;
    
    @include media-breakpoint-down(sm) {
		width: 100%;    
    }

    .fal {
        position: relative;
        top: 1px;
    }
}

.customerCard-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.customerCard-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    
    .btn + .btn {
	    margin-left: 5px;
    }
}

.customerCard-content-item {
    color: $r2-purple;
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

	@include media-breakpoint-down(sm) {
		width: 100%;    
    }

    .tag,
    .fal {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}