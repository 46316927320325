.rrule-setup {}

.rrule-setup-day {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.rrule-setup-day-label {
    margin-right: 15px;
}


.rrule-setup-month {}

.calendar-grid {
    .week {
        display: flex;
        height: 40px;
    }
    .week.first-week {
        .day {
            border-top: 1px solid $r2-border;
        }

        .day:first-child {
            border-top-left-radius: $border-radius;
        }

        .day:last-child {
            border-top-right-radius: $border-radius;
        }
    }

    .week:last-child {
        .day:first-child {
            border-bottom-left-radius: $border-radius;
        }

        .day:last-child {
            border-bottom-right-radius: $border-radius;
        }
    }

    .day {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        padding: 6px 5px 5px;
        border: 1px solid $r2-border;
        border-top: none;
        border-left: none;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: $r2-purple;
    }
    .day:hover {
        background: $r2-table-hover-bg;
        color: $r2-blue;
        cursor: pointer;
    }
    .day.no-border {
        border-color: transparent;
    }
    .day.selected {
        background: $r2-table-hover-bg;
        color: $r2-blue;
    }

    .week > .day:first-child {
        border-left: 1px solid $r2-border;
    }
}

.rrule-setup-year-grid {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 0 0;
    grid-template-areas: ". . ." ". . ." ". . ." ". . .";


    .month {
        padding: 6px 5px 5px;
        border: 1px solid $r2-border;
        border-top: none;
        border-left: none;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: $r2-purple;

        &:first-child {
            border-top-left-radius: $border-radius;
        }

        &:nth-child(3) {
            border-top-right-radius: $border-radius;
        }

        &:nth-child(10) {
            border-bottom-left-radius: $border-radius;
        }

        &:last-child {
            border-bottom-right-radius: $border-radius;
        }
    }

    .month:nth-child(1),
    .month:nth-child(2),
    .month:nth-child(3) {
        border-top: 1px solid $r2-border;
    }

    .month:nth-child(1),
    .month:nth-child(4),
    .month:nth-child(7),
    .month:nth-child(10) {
        border-left: 1px solid $r2-border;
    }

    .month:hover {
        background: $r2-table-hover-bg;
        color: $r2-blue;
        cursor: pointer;
    }

    .month.selected {
        background: $r2-table-hover-bg;
        color: $r2-blue;
    }
}