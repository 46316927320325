// Variables
@import 'variables';

// Webshop variables
@import 'webshop-variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
//Font awesome
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-pro/scss/solid.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss";
@import "~@fortawesome/fontawesome-pro/scss/light.scss";
@import "~@fortawesome/fontawesome-pro/scss/brands.scss";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
//DataTables
@import "~datatables.net-dt/css/jquery.dataTables";
//Select2
@import "node_modules/select2/dist/css/select2";
@import "node_modules/toastr/toastr";

.custom-file-input {
    opacity: inherit;
}
:root{
    --check-color: #0096FF;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

@import "_partials/typography";
@import "_partials/buttons";
@import "_partials/tables";
@import "_partials/forms";

@import "_partials/app";
@import "_partials/portal";

@import "_partials/header";
@import "_partials/portalNavigation";
@import "_partials/sidepanel";
@import "_partials/notificationBars";
@import "_partials/todoBar";

@import "_partials/grid";
@import "_partials/page";

@import "_partials/icons";
@import "_partials/logo";

@import "_partials/tags";
@import "_partials/badges";
@import "_partials/alerts";
@import "_partials/notifications";
@import "_partials/labels";
@import "_partials/conversations";

@import "_partials/modal";
@import "_partials/summary";

@import "_partials/dropdown";
@import "_partials/quicklinks";
@import "_partials/lists";
@import "_partials/boxSet";

@import "_partials/box";
@import "_partials/card";
@import "_partials/customerCard";

@import "_partials/orderTable";
@import "_partials/orderCard";

@import "_partials/products";
@import "_partials/orders";
@import "_partials/contracts";
@import "_partials/offers";
@import "_partials/documents";

@import "_partials/thumbnails";

@import "_partials/status";

//@import "_partials/planner";

@import "_partials/rrule";
@import "_partials/maillog";

@import "_partials/paymentMethods";

.color--primary {
	color: var(--primary);
}

.bgColor--primary {
	background-color: var(--primary);
}

.btn-light {
	border-color: var(--primary) !important;
	color: var(--text-black) !important;

	&:hover,
	&:focus {
		background-color: var(--primary) !important;
	}
}

.form-item--radio input:checked + label:before {
	background-color: var(--primary);
}
