.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.page-header-title {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.page-header-title-action {
    color: $body-color;
    margin-right: 15px;
    margin-top: 3px;
    font-weight: bold;
    font-size: 12px;

    .fal {
        margin-right: 5px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: $body-color;
    }
}

.page-header-title-name {
    font-size: 20px;
    font-weight: bold;
}

.page-header-subtitle {
    width: 100%;
}

.page-header-tools {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
        margin-left: 10px;
    }
}

.page-header-status {
    font-weight: bold;
    color: $r2-purple;
    margin-right: 10px;
}
