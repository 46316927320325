
.product {}



.product-image-container {
    position: relative;
    min-height: 40px;
    padding-left: 50px;

    .product-image-box {
        position: absolute;
        top: 0; left: 0;
    }
}

.product-image-box--bottom {
    .product-image-box {
        top: auto;
        bottom: 0;
    }
}

.product-image-box {
    width: 40px;
    height: 40px;
    border: 1px solid $r2-border;
    border-radius: $border-radius;
    padding: 4px;
    display: flex;
    margin-right: 10px;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
    }
}

.product-image {
    width: 30px;
    height: 30px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
   
}

.productCards {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px -16px;
	justify-content: flex-start;
    align-items: center;
}

	.productCards-plus {
		width: 46px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 1px solid $r2-border;
		margin: 0 8px 16px;
	}

	.productCard {
		margin: 0 8px 16px;
		width: 33.33%;
		min-width: 260px;
		border: 1px solid $r2-border;
	    border-radius: $border-radius;
	    padding: 16px;
	    position: relative;
	}
		
		.productCard-discount {
			position: absolute;
			font-size: 12px;
			top: -10px;
			right: 10px;
			line-height: 1;
			padding: 3px 8px;
			background: $r2-blue-20;
			border-radius: 9px;
		}
		
		.productCard-title {
			font-weight: bold;
			margin: 0 0 5px;
		}
		
		.productCard-subtitle {
			color: $r2-purple;
			font-size: 14px;
		}
		
		.productCard-figure {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 15px 0;
		}
		
		.productCard-price {
			text-align: right;
			font-weight: bold;
		}