.logo {
    display: inline-block;
    min-height: 36px;
    color: $r2-purple;
}

.logo--withImage {

    img {
        width: 70px;
    }
}

.logo-image {
    width: 70px;
    height: 36px;
    display: inline-block;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
}