@import "social-login-buttons";
.btn {
    padding: 0.4375rem 0.875rem;
    color: $r2-purple;
    font-weight: bold;
    border-radius: 4px;
}

.pointer {
    cursor: pointer;
}

		.btn-flex {
		    width: 100%;
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		    flex-wrap: wrap;

		    .fal,
		    .far,
		    .fas,
		    .fa {
		        color: $r2-blue;
		    }
		}

		.btn-outlined,
		.btn-light {
		    background: white !important;
		    border: 1px solid $r2-border;

		    &:hover,
		    &:focus {
		        box-shadow: none;
		    }

		    &.disabled {
		        background: none;
		        border: 1px solid $r2-border;
		        opacity: 0.5;
		    }
		}

		.btn-outlinedBlue {
			color: $r2-blue;
			border: 1px solid $r2-blue;

			&:hover,
			&:focus {
				color: $r2-blue;
			}
		}

		.btn-light--blue {
		    color: $r2-blue !important;
		}

		.btn-green {
		    background: $r2-sys-green !important;
		    color: white;
		    border-color: transparent;

		    &:hover,
		    &:focus {
			    background: $r2-sys-green !important;
			    color: white;
		    }
		}

		.btn-white {
			background: white;
			color: $r2-purple;
		}

		.alert--success {
			.btn-white {
				color: $r2-sys-green;
			}
		}

		.btn-primary {
		    color: white;
		}

		.btn-default {
		    background: $r2-purple-5;
		}

		.btn-tool {
		    background: $r2-purple-20;
		    border-color: transparent;
		    color: $r2-purple;
		}

		.btn-warning,
		.btn-danger {
		    color: white !important;

		    &:hover,
		    &:focus {
		        color: white !important;
		    }
		}
        .btn-outline-danger{
            color: $r2-sys-red !important;
            border-color: $r2-sys-red !important;

            &:hover,
            &:focus {
                background: $r2-sys-red;
                color: white !important;
            }
        }

		.btn-large {
		    padding: 14px 24px;
		}

		.btn-lg {
		    font-size: 16px;
		    height: 40px;
		    border-radius: $border-radius;
		    border-color: $r2-border;
		}

		.btn-xl {
			height: 46px;
		}

		.btn-link {
		    padding: 0;
		    font-weight: bold;
		    color: $r2-black;
		    font-size: 12px;


		    &:hover,
		    &:focus {
		        text-decoration: none;
		        color: $r2-black;
		    }


		}

		.btn-link + .btn-link {
			margin-left: 15px;
		}

		.btn-link--blue {
		    color: $r2-blue;
		}

		.btn-link--light {
			color: $r2-purple;
		}

		.btn-select {
		    -webkit-appearance: none;
		    background: $r2-sys-green url("/images/icons/white/icon-chevron-down.svg") right center no-repeat;
		    background-position: calc(100% - 10px) center;
		    background-size: 12px;
		    color: white;
		    padding: 0.4375rem 2rem 0.4375rem 0.875rem;;

		    &:hover,
		    &:focus {
		        color: white;
		    }
		}

		.btn-success {
			color: white;
            &.disabled{
                color: #fff;
            }
		}

		.btn--withIcon {
			position: relative;
			font-weight: normal;
			padding-left: 54px;
			overflow: hidden;
		}

		.btn--iconOffset {
			.fal, .far, .fas, .fa {
				margin-left: 5px;
			}
		}

		.btn--withCollapse {
			margin: 16px 0 0;

			&[aria-expanded="true"] {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		.btn--withMobileIcon {
			.btn-text {
				display: inline;
			}

			.btn-mobileIcon {
				display: none;
			}

			@include media-breakpoint-down(md) {
				.btn-text {
					display: none;
				}

				.btn-mobileIcon {
					display: inline;
				}
			}
		}

	.btn-icon {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

		.btn-icon--primary {
			background: $r2-blue-10;
			color: $r2-blue;
		}

		.btn-icon--danger {
			background: $r2-sys-red-bg;
			color: $r2-sys-red;
		}

		.btn-icon--warning {
			background: $r2-sys-orange-bg;
			color: $r2-sys-orange;
		}

.btn-select:focus {
    outline: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    background: $r2-table-hover-bg;
    color: $r2-blue;
    border-color: $r2-border;
}

.buttonGroup {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px;
}

	.buttonGroup--2 {
		> div {
			width: 50%;
			padding: 0 8px 16px;
		}

		.btn {
			display: block;
			width: 100%;
		}
	}

.link {
	display: inline-block;
}

		.link--underlined {
			text-decoration: underline;
		}

.box + .btn-block {
	margin-top: 30px
}
