.orderCards {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	
	@include media-breakpoint-up(md) {
		margin-bottom: -20px;
	}
}

	.orderCards-item {
		width: 100%;
		display: flex;
		padding: 0 10px;
		
		@include media-breakpoint-up(md) {
			width: 50%;
			margin-bottom: 10px;
		}
		
		@include media-breakpoint-up(xl) {
			width: 33.33%;
			margin-bottom: 10px;
		}
	}
	
.orderCard {
	@extend .box;
	border: 1px solid $r2-border;
	margin-bottom: 10px;
	font-size: 14px;
	
	.btn {
		@include media-breakpoint-down(sm) {
			padding: 14px 24px;
		}
	}
}

	.orderCard-header {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $r2-border;
		color: $r2-purple;
		position: relative;
		flex-wrap: wrap;
		
		&:hover,
		&:focus {
			text-decoration: none;
			color: $r2-purple;
		}
		
		b {
			color: black;
			font-size: 15px;
		}
		
		.box-product-name {
			font-size: 15px;
			color: black;
			
			.box-product-type {
				font-size: 14px;
			}
		}
	}
		
		.orderCard-header-teaser {
			width: 100%;
			padding: 10px 10px 10px;
			
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			
			@media (min-width: 400px) {
				width: 50%;
			}
		}
		
		.orderCard-header-address {
			display: block;
			width: 100%;
			padding: 10px 40px 10px 10px;
			
			@media (min-width: 400px) {
				width: 50%;
			}
			
		}
		
		.orderCard-header-action {
			position: absolute;
			top: 15px;
			right: 15px;
			padding: 5px;
			
			img {
				width: 17px;
			}
		}
	
	.orderCard-footer {
		padding: 10px;
	}
	
		.orderCard-footer-actions {
			margin-top: 10px;
			margin-bottom: 10px;
			
			&:first-child {
				margin-top: 0;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}