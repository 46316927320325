.callBar {
	@extend .alert;
	@extend .alert--success;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	padding-left: 96px;
	transition: all 0.3s ease;
	position: fixed;
	width: 100%;
	z-index: 10;
	border-radius: 0 !important;
	top: -52px;
	display: none;

	.btn {
		margin: 0 10px;
	}

    &.callBar-hold {
        @extend .alert--orange;

        .js-callbar-open-btn {
            color: $r2-sys-orange !important;
        }
    }
}

.presenceBar {
	@extend .alert;
	@extend .alert--orange;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	padding-left: 96px;
	transition: all 0.3s ease;
	position: fixed;
	width: 100%;
	z-index: 10;
	border-radius: 0 !important;
	top: -36px;
	display: none;

	.btn {
		margin: 0 10px;
	}
}

body.sidebarActive {
	.presenceBar {
		padding-left: 277px;
	}
}


body.sidebarActive {
	.callBar {
		padding-left: 277px;
	}
}

.body.animateBars {

}

body.callBarActive {
	.callBar {
		transform: translateY(52px);
	}

	.app-main {
		padding-top: calc(24px + 52px);
	}

	.sidePanel,
	.conversationBar {
		top: 52px;
	}

	&.animateBars {
		.callBar {
			animation: 0.5s cubic-bezier(.165,.84,.44,1) 0s 1 slideCallBar;
			animation-fill-mode: forwards;
		}
	}


	.callBar {
		display: block;
	}
}

body.presenceBarActive {
	.presenceBar {
		transform: translateY(36px);
	}

	.app-main {
		padding-top: calc(24px + 36px);
	}

	.sidePanel,
	.conversationBar {
		top: 36px;
	}

	.presenceBar {
		display: block;
	}

	&.callBarActive {
		.callBar,
		.presenceBar {
			transform: translateY(88px);
		}

		.app-main {
			padding-top: calc(24px + 88px);
		}

		.sidePanel,
		.conversationBar {
			top: 88px;
		}

		.presenceBar {
			top: -88px;
		}
	}

	&.animateBars {
		.presenceBar {
			animation: 0.5s cubic-bezier(.165,.84,.44,1) 0s 1 slidePresenceBar;
			animation-fill-mode: forwards;
		}

		&.callBarActive {
			.presenceBar,
			.callBar {
				animation: 0.5s cubic-bezier(.165,.84,.44,1) 0s 1 slideCallPresenceBar;
				animation-fill-mode: forwards;
			}
		}
	}
}

@keyframes slideCallBar {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(52px);
  }
}

@keyframes slidePresenceBar {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(36px);
  }
}

@keyframes slideCallPresenceBar {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(88px);
  }
}
