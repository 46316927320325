.conversationBar {
	position: fixed;
    top: 0;
    right: 0;
    background: white;
    bottom: 0;
    width: 580px;
//    transform: translateX(376px);
    transition: all 0.3s ease;
    z-index: 20;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 1920px) {
	    transform: translateX(580px);
    }
    
    .conversations-inbox {
	    padding: 16px;
    }
}

.btn-tool--conversationBar {
	@media (min-width: 1921px) {
		display: none;
	}
}

@media (max-width: 1920px) {
	body.conversationBarActive {
		.conversationBar {
			transform: translateX(0);
			box-shadow: -17px 0 94px rgba(0, 0, 0, 0.2);
		}
	}
}

@media (min-width: 1921px) {
	.app-main--conversationBarPresent {
		padding-right: 610px;
		
		.quicklink {
			right: calc(610px + 10px);
		}
	}
	
	.conversationBar {
		box-shadow: none !important;
		border-left: 1px solid $r2-border;
	}
	
	
}


	.conversationBar-header {
		background: $r2-blue;
	    padding: 0 16px;
	    position: relative;
	    display: flex;
	    justify-content: flex-start;
	    align-items: center;
	    font-size: 18px;
	    color: white;
	}
	
		.conversationBar-nav {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			color: white;
			border: none;
		}
		
			.conversationBar-nav-toggle {
				background: $r2-blue-50;
				color: white;
				display: none;
				font-size: 18px;
				width: 36px;
			    height: 36px;
			    padding: 0;
			    justify-content: center;
			    align-items: center;
				
				@media (max-width: 1921px) {
					display: flex;
				}
				
				&:hover,
				&:focus {
					text-decoration: none;
					color: white;
				}
			}
		
			.conversationBar-nav-label {
				padding: 16px;
				font-weight: bold;
			}
		
			.conversationBar-nav-item {
				padding: 16px 2px;
				position: relative;
				margin: 0 12px;
				color: $r2-blue-dark;
				font-weight: bold;
				
				&:after {
					content: "";
					height: 3px;
					width: 100%;
					left: 0;
					bottom: 0;
					background: white;
					border-radius: 3px 3px 0 0;
					position: absolute;
					display: none;
				}
				
				&.active {
					color: white;
					
					&:after {
						display: block;
					}
				}
				
				&:hover,
				&:focus {
					color: white;
					text-decoration: none;
				}
			}
			
	.conversationBar-content {
		flex: 1;
		display: flex;
	}

.conversations {
	overflow: hidden;
	flex: 1;
	display: flex;
	width: 100%;
}

	.conversations-inbox {
		flex: 1;
		overflow: scroll;
		width: 100%;
		
		> .color--light {
			padding: 10px 16px 0;
		}
	}
		
		.conversations-inbox-message {
			background: white;
			position: relative;
			border-radius: $border-radius;
			border: 1px solid $r2-border;
			padding: 14px;
			margin-bottom: 8px;
			font-size: 14px;
			cursor: pointer;
			width: 100%;
			min-height: 72px;
			
			&:hover,
			&:focus {
				box-shadow: 0 6px 16px 0 rgba(153,155,168,0.10);
			}
		}
		
				.conversations-inbox-message--selected {
					background: $r2-blue-10;
					border-color: $r2-blue-10;
				}
				
				.conversations-inbox-message--withType {
					padding-left: 70px;
					//min-height: 72px;
				}
		
			.conversations-inbox-message-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-weight: bold;
			}
			
				.conversations-inbox-message-header-title {
					position: relative;
				}
			
					.conversations-inbox-message-unread {
						position: absolute;
						top: 0px;
						left: -11px;
					}
			
				.conversations-inbox-message-header-timestamp {
					font-size: 12px;
					color: $r2-purple;
					font-weight: normal;
				}
			
			.conversations-inbox-message-content {
				
			}
			
			.conversations-inbox-message-type {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background: $r2-purple;
				color: white;
				text-align: center;
				padding-top: 10px;
				line-height: 1;
				font-weight: bold;
				font-size: 16px;
				position: absolute;
				top: 18px;
				left: 16px;
			}
	
		.conversations-inbox-actions {
			text-align: center;
			margin-top: 1rem;
		}
			
	.conversations-thread {
		position: relative;
		background: $r2-purple-5;
		padding: 16px;
		
		&:before {
			position: absolute;
			top: -17px;
			left: 0;
			width: 100%;
			height: 17px;
			background-image: linear-gradient(180deg,rgba(0,0,0,0),rgba(0,0,0,0.08));
			content: "";
			z-index: 2;
		}
	}
	
			.conversations-thread--conversationBar {
				position: absolute;
				bottom: 0;
				left: 0;
				top: 200px;
				right: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 0;
				
				.conversations-thread-messages {
					max-height: none;
					flex: 1;
					padding: 16px;
				}
			}
			
			.conversations-thread--modal {
				padding: 0;
				box-shadow: none;
				
				.conversations-thread-messages {
					padding: 16px;
					max-height: 300px;
				}
			}
			
			.conversations-thread--conversationNew {
				top: auto;	
				
				.conversations-thread-messages {
					padding: 0;
				}
			}
			
		.conversations-thread-header {
			padding: 16px 32px 16px 16px;
			background: white;
			min-height: 68px;
			border-bottom: 1px solid $r2-border;
		}
		
			.conversations-thread-header-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			
				.conversations-thread-header-title {
					padding-left: 55px;
					margin-bottom: 16px;
					
					
					h5 {
						font-size: 16px;
						margin-bottom: 0;
					}
					
					&:last-child {
						margin-bottom: 0;
					}
				}
				
				.conversations-thread-header-title {
					margin-bottom: 16px;
				}
				
					.conversations-thread-header-title-action {
						text-align: right;
					}
				
			.conversations-thread-header-actions {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				
				> * + * {
					margin-left: 5px;
				}
			}
			
			.conversations-thread-header-close {
				position: absolute;
				top: 10px;
				right: 12px;
				color: $r2-purple;
				cursor: pointer;
				
				&:hover,
				&:focus {
					color: $body-color;
				}
			}
	
		.conversations-thread-messages {
			max-height: 500px; 
			overflow-y: auto; 
			display: flex; 
			flex-direction: column-reverse;
		}
		
			.conversations-thread-messages-empty {
				padding: 16px 0;
				text-align: center;
			}
		
			.conversations-thread-message-wrapper {
				position: relative;
				padding-left: 44px;
				margin-bottom: 8px;
				
				&:first-child {
					margin-bottom: 0;
				}
			}		
			
					.conversations-thread-message-wrapper--backend {
						padding-left: 0;
						padding-right: 44px;
						
						.conversations-thread-message-avatar {
							left: auto;
							right: 0;
							background: $r2-blue;
						}
					}
			
				.conversations-thread-message-avatar {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					background: $r2-purple;
					color: white;
					text-align: center;
					padding-top: 10px;
					line-height: 1;
					font-weight: bold;
					font-size: 16px;
					position: absolute;
					top: 0;
					left: 0;
				}
			
				.conversations-thread-message {
					background: white;
					border: 1px solid $r2-border;
					border-radius: $border-radius;
					margin-bottom: 0px;
					min-height: 36px;
				}
				
					.conversations-thread-message-header {
						padding: 14px 14px 0;
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-weight: bold;
					}
					
						.conversations-thread-message-header-timestamp {
							font-size: 12px;
							color: $r2-purple;
							font-weight: normal;
						}
					
					.conversations-thread-message-content {
						padding: 14px;
					}
					
					.conversations-thread-message-footer {
						border-top: 1px solid $r2-border;
						padding: 8px 14px;	
					}
					
					
	.conversations-new {
		background: white;
	}
	
		.conversations-new-header {
			font-weight: bold;
			color: $r2-purple;
			border-bottom: 1px solid $r2-border;
			padding: 8px 16px;
		}
		
		.conversations-new-content {
			padding: 8px 16px;
		}
		
			.conversations-new-control {
				border: 1px solid $r2-border;
				border-radius: $border-radius;
				padding: 8px 12px;
				width: 100%;
			}
		
		.conversations-new-footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 16px;
		}
		
// Create message

.conversations-message-create {

}
	
	.conversations-message-create-control {
	    padding: 10px 16px;
	    background: none;
	    width: 100%;
	    border: none;
	    display: block;
	    border-top: 1px solid $r2-border;
	}

		.conversations-message-create-control:focus {
		    outline: none;
		}
		
		.conversations-message-create-control::placeholder {
		    color: $r2-purple;
		}

	.conversations-message-create-select {
	    display: flex;
	    justify-content: space-between;
	    border-top: 1px solid $r2-border;
	}
		
		.conversations-message-create-select-label {
		    padding: 10px 16px;
		    color: $r2-purple;
		    width: 33%;
		    max-width: 160px;
		    margin: 0;
		}

		.conversations-message-create-select-control {
			flex: 1;
		    -webkit-appearance: none;
		    padding: 10px 40px 10px 16px;
		    border: none;
		    border-radius: 0;
		    background: url("/images/icons/icon-chevron-down.svg") right center no-repeat;
		    background-position: calc(100% - 10px) center;
		    background-size: 12px;
		}
			
			.conversations-message-create-select-control:focus {
			    outline: none;
			}
			
	.conversations-message-create-body {
		padding: 10px 16px;
		border-top: 1px solid $r2-border;
	}
	
		.conversations-message-create-body-control {
			border: 1px solid $r2-border;
			border-radius: $border-radius;
			width: 100%;
			padding: 10px 16px;
			display: block;
		}

	.conversations-message-create-tools {
	    display: flex;
	    justify-content: space-between;
	    flex-wrap: wrap;
	    align-items: center;
	    padding: 5px 16px 12px;
	
	    .btn {
	        margin-left: 3px;
	    }
	}
	
		.conversations-message-create-tools-attachments {
			flex: 1;
		}
		
// Mobile

.conversations-mobileBar-toggle,
.conversations-mobileBar-close {
	display: none;
}

@include media-breakpoint-down(sm) {
	.conversations-mobileBar {
		position: fixed;
		top: 0; left: 0;
		background: white;
		height: 100vh;
		z-index: 1000;
		padding: 0 !important;
		transform: translateY(100%);
	    transition: all 0.4s ease;
	    overflow: scroll;
	    -webkit-overflow-scrolling: touch;
	    
	    > .box {
		    display: flex;
		    flex-direction: column;
		    height: 100vh;
		    border: none;
	    }
	    
	    .conversations-inbox {
		    padding: 16px 16px 0;
	    }
	    
	    .conversations-new {
		    border-top: 1px solid $r2-border;
	    }
	    
	    .conversations-thread {
		    max-height: 46vh;
		    padding-top: 0;
		    padding-bottom: 0;
		    
		    .conversations-thread-messages {
			    max-height: 100%;
			 
				.conversations-thread-message-wrapper:first-child {
					padding-bottom: 16px;
				}
				
				.conversations-thread-message-wrapper:last-child {
					padding-top: 16px;
					
					.conversations-thread-message-avatar {
						top: 16px;
					}
				}
		    }
	    }
	}
	
	.conversations-mobileBar-toggle {
		width: 60px;
	    height: 60px;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
	    border-radius: 50%;
	    color: white;
	    z-index: 10;
	    background: $r2-blue;
	    font-size: 24px;
	    text-decoration: none;
	    transition: all 0.1s ease;
	    position: fixed;
	    bottom: 30px;
	    right: 30px;
	    z-index: 500;
	    cursor: pointer;
	   
	
	    &:hover,
	    &:focus {
	        text-decoration: none;
	        color: white;
	        outline: none;
	    }
	}
	
	.conversations-mobileBar-close {
		position: absolute;
		top: 21px;
		right: 20px;
		z-index: 3000;
		font-size: 24px;
		display: block;
		cursor: pointer;
	}
	
	.body--conversationMobileBarActive {
		overflow: hidden;
		
		.conversations-mobileBar {
			transform: translateY(0);
		}
	}
}


// Inbox

.inbox {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

	.inbox-header {
		padding: 24px 24px 0;
	}
	
		.inbox-header-tools {
			border-top: 1px solid $r2-border;
			border-bottom: 1px solid $r2-border;
			margin: 0 -24px;
			padding: 0 12px 0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: flex-end;
		}
		
			.inbox-header-tools-search {
				padding: 12px 12px;
				
				.form-control {
					margin: 0;
				}
			}
		
		.nav-tabs--inbox {
			border-bottom: none;
			
			.nav-item {
		        margin: 0 20px 0 0px;
		
		
		        &:last-child {
		            margin-right: 0;
		        }
		    }
		
		    .nav-link {
		        border-radius: 0;
		        padding: 5px 5px 20px;
		        font-size: 16px;
		        font-weight: bold;
		        color: $r2-purple;
		        border: none;
		        position: relative;
		
		        &.disabled {
		            color: $r2-purple-20;
		            opacity: 1;
		        }
		
		        &:before {
		            position: absolute;
		            bottom: 0;
		            left: 0;
		            width: 100%;
		            height: 3px;
		            border-radius: 3px 3px 0 0;
		            background: $r2-blue;
		            content: "";
		            display: none;
		        }
		
		        &.active {
		            background: none;
		            color: $r2-blue;
		
		            &:before {
		                display: block;
		            }
		        }
		
		        &:hover,
		        &:focus {
		            &:before {
		                display: block;
		            }
		        }
		    }
		}
	
	.conversations-inbox-messages {
		
	}
	
	.conversations--inbox {
		flex: 1;
		display: flex;
		
		.conversations-inbox {
			padding: 24px;
		}
	}
	
// Stats

	.conversations-stats {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;
	}
	
		.conversations-stat {
			margin: 8px;
			border: 1px solid $r2-border;
			border-radius: $border-radius;
			background: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: calc(16.66% - 16px);
			padding: 16px;		
		}
		
			.conversations-stat-counter {
				font-weight: bold;
				font-size: 20px;
			}
			
			.conversations-stat-title {
				color: $r2-purple !important;
			}
			
	.conversations-teamStats {
		background: white;
		border: 1px solid $r2-border;
		border-radius: $border-radius;
		margin-top: 8px;
	}
	
		.conversations-teamStats-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid $r2-border;
			padding: 0 16px 0 0;
			min-height: 50px;
		}
		
				.conversations-teamStats-item--header {
					border-top: none;
					
					.conversations-teamStats-item-content {
						font-size: 16px;
					}
				}
		
			.conversations-teamStats-item-content,
			.conversations-teamStats-item-stat {
				padding: 10px 16px;
			}
			
			.conversations-teamStats-item-content {
				flex: 1;
				font-weight: bold;
			}
			
			.conversations-teamStats-item-stat {
				width: 40px;
				text-align: center;
			}
			
			
// Modal 
.conversations-modal {
	.modal-header {
		padding: 0;
		display: block;
		position: relative;
		
		.close {
			position: absolute;
			top: 24px;
			right: 17px;
		}
	}
	
	.modal-body {
		background: $r2-purple-5;
		padding: 16px;
	}
	
	.modal-footer {
		padding: 0;
	}
	
	.conversations-thread-header {
		padding-right: 50px;
	}
	
	.conversations-thread-header-title-action {
		text-align: right;
	}
	
	.conversations-thread-header-close {
		display: none;
	}
}