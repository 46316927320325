.thumbnails {
	display: flex;
	margin: 20px -5px 0;
}

	.thumbnail {
		margin: 0 5px 10px;
		border-radius: $border-radius;
		border: 1px solid $r2-border;
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
	
			.thumbnail--add {
				cursor: pointer;
				font-size: 21px;
				color: $r2-purple;
				order: 2;
			}