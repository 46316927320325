.quicklink {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 2;
}

.quicklink-button {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    color: white;
    z-index: 10;
    background: $r2-blue;
    font-size: 24px;
    text-decoration: none;
    transition: all 0.1s ease;
    position: relative;

    &:hover,
    &:focus {
        text-decoration: none;
        color: white;
        outline: none;
    }
}

.quicklink-dropdown {
    background: white;
    border-radius: $border-radius;
    z-index: 9;
    bottom: 30px;
    right: 30px;
    width: 280px;
    display: flex;
    flex-direction: column;
    position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: all 0.1s ease;
    transform: none !important;
    top: auto !important;
    left: auto !important;
    bottom: 30px !important;
    right: 30px !important;
    padding: 0;
    margin: 0;

    &.show {
        max-height: 1000px;
    }
}

.quicklink-action {
    padding: 24px 15px 24px 76px;
    position: relative;
    display: block;
    border-bottom: 1px solid $r2-border;
    color: $r2-black;
    font-weight: bold;
    text-decoration: none;


    &:hover,
    &:focus {
        text-decoration: none;
        color: $r2-black;

        .quicklink-action-icon {
            background: $r2-blue;
            color: white;
        }
    }

    &:last-child {
        border-bottom: none;
    }
}

.quicklink-action-icon {
    width: 36px;
    height: 36px;
    border-radius: $border-radius;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    left: 24px;
    background: $r2-blue-10;
    color: $r2-blue;
    display: flex;
    justify-content: center;
    align-items: center;
}
