.dropdown {
    .dropdown-menu {
        border: transparent;
        box-shadow: 0 6px 20px rgba(153, 155, 168, 0.71);
        z-index: 12;
        margin-top: 0;

        .form-group {
            display: block;
            margin: 0;

            .control-label {
                flex: none;
                max-width: 100%;
            }

            .col-sm-10 {
                flex: none;
                max-width: 100%;
            }
        }

        .btn {
	        display: block;
			width: 100%;
			padding: 4px 16px;
			clear: both;
			font-weight: 400;
			color: #333333 !important;
			text-align: inherit;
			white-space: nowrap;
			background: none !important;
			border: 0;
			border-radius: none;
			box-shadow: none !important;

			&:hover,
			&:focus {
				color: #262626 !important;
				text-decoration: none;
				background-color: #F9F9F8 !important;
			}
        }
    }
}

.dropdown-item {
    padding: 4px 16px;
}

.dropdown-toggle::after {
    display: none;

}

.dropleft .dropdown-toggle::before {
	display: none;
}
