.summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -10px 30px;
    position: relative;
}

		.summary--vertical {
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 0;
			
			.summary-item {
				margin-bottom: 16px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.summary--withCollapse {
			padding-right: 60px;
			margin-bottom: 10px;
		}
		
		.summary--pullUp {
			margin-top: -20px;
		}
		
		.summary--collapseMobile {
			flex-wrap: wrap;
			
			.summary-item {
				@include media-breakpoint-down(md) {
					width: 100%
					
				}
				
				& + .summary-item {
					@include media-breakpoint-down(md) {
						margin-top: 15px;
					}
				}
			}
		}

	.summary-item {
	    padding: 0 10px;
	    display: flex;
	    align-items: center;
	}
	
			.summary-item--large {
				font-size: 16px;
				
				.summary-item-title {
					font-size: 18px;
				}
			}
	
		.summary-item-title {
		    font-weight: bold;
		    line-height: 1.3;
		}
			
			.summary-item-subtitle {
			    font-weight: normal;
			    color: $r2-purple;
			    line-height: 1.3;
			}
			
	.summary-collapse {
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -12px;
		height: 24px;
		width: 20px;
		background: url("/images/icons/icon-chevron-down.svg") center center no-repeat;
		background-size: contain;
		transform: rotate(180deg);
		
		&.collapsed {
			transform: rotate(0deg);
		}
	}