.maillog {
}

.maillog-header {
    display: flex;
    justify-content: space-between;
}

.maillog-header-time {

}

.maillog-details {
    display: flex;
    flex-wrap: wrap;
}

.maillog-detail {
    margin-right: 10px;
    margin-bottom: 5px;

    span {
        color: #aaa;
        display: inline-block;
        margin-right: 10px;
    }
}

.maillog-message {
    box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
    margin-top: 15px;

    .wrapper {
        min-height: 1px;
    }
}