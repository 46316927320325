.portal {
    min-height: 100vh;
    position: relative;
}

.portal-cta {
	display: none;
	
	@include media-breakpoint-down(sm) {
		display: flex;
	}
	
	background: white;
	border-radius: 0 0 $border-radius $border-radius;
	padding: 10px 20px;
	box-shadow: 0 -2px 5px 6px rgba(0,0,0,0.08);
	margin: -20px -20px 20px -20px;
}

.portal-header {}

		.portal-header--desktop {
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		
		.portal-header--mobile {
			display: none;
			
			@include media-breakpoint-down(sm) {
				display: flex;
			}
			
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 100;
			background: white;
			border-bottom: 1px solid #F0F0F3;
			justify-content: center;
			align-items: center;
			height: 60px;
		}
		
	.portal-header-brand {
		position: absolute;
		top: 15px;
		height: 30px;
		left: 20px;
	}
	
	.portal-header-back {
		position: absolute;
		-webkit-appearance: none;
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		width: 24px;
		height: 36px;
		left: 20px;
		top: 12px;
		background: url("/images/icons/icon-arrow-right.svg") center center no-repeat;
		background-size: 24px;
		transform: rotate(180deg);
	}
	
	.portal-header-title {
		width: 100%;
		text-align: center;
		padding: 11px 50px 10px;
		font-size: 18px;
		font-weight: bold;
	}
	
	.portal-header-nav {
		position: absolute;
		border-radius: $border-radius;
		right: 20px;
		top: 12px;
		width: 36px;
		height: 36px;
		background: var(--primary);
		color: white;
		cursor: pointer;
		padding: 11px 7px 0;
		
		.body--portalNavActive & {
			.icon-bar:nth-child(1) {
				opacity: 0;
			}
			
			.icon-bar:nth-child(2) {
				transform: rotate(45deg);
			}
			
			.icon-bar:nth-child(3) {
				transform: rotate(-45deg);
				margin-top: -3px;
			}
		}
		
		.icon-bar {
			display: block;
			width: 22px;
			height: 3px;
			border-radius: 1px;
			background: white;
			transition: all 0.15s ease-in-out;
			
			& + .icon-bar {
				margin-top: 3px;
			}
		}
	}

.portal-main {
    padding: 24px 30px 24px 30px;
    transition: padding 0.3s ease;
    
    @include media-breakpoint-down(sm) {
	    padding: 60px 20px 20px;
	    
	    .box {
		    border: 1px solid $r2-border;
	    }
	    
	    .boxGrid-col + .boxGrid-col {
		    margin-top: 10px;
	    }
    }
    
    > .portal-main-jumbotron {
	    @include media-breakpoint-up(md) {
		    border-radius: 0;
		    margin: -24px -30px -44px;
			padding: 24px 30px 64px;
	    }
    }
}

	.portal-main-jumbotron {
		background: $r2-table-hover-bg;
		border-radius: $border-radius;
		margin-bottom: 30px;
		margin: -24px -24px -44px;
		padding: 24px 24px 64px;
		
		@include media-breakpoint-down(sm) {
			background: white;
			margin: -20px -20px -44px;
			padding: 20px 20px 64px;
			border-radius: 0;
	    }
		
		.summary {
			margin-bottom: 0;
		}
	}

.portal-content {
	display: flex;
	flex-direction: column;
	
	@include media-breakpoint-down(sm) {
		padding-top: 20px;	
	}
}

.portal-template {}

		.portal-template--desktop {
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		
		.portal-template--mobile {
			display: none;
			
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		
.portal-footer {
	@include media-breakpoint-down(sm) {
		display: none;
	}
}