.contractCards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
}

	.contractCard-wrapper {
	    width: 50%;
	    padding: 0 7px;
	    margin-bottom: 14px;
	    display: flex;

	    @media (min-width: 1400px) {
	        width: 33.33%;
	    }
	}

		.contractCard {
		    border: 1px solid $r2-border;
		    border-radius: $border-radius;
		    background: white;
		    width: 100%;
		    padding-bottom: 120px;
		    position: relative;
		}

			.contractCard-header {
			    border-bottom: 1px solid $r2-border;
			    padding: 24px 24px 24px 24px;
			    position: relative;
			}

				.contractCard-header-product {
				    padding-right: 36px;
				    display: flex;
				    flex-wrap: wrap;
				    justify-content: space-between;
				    align-items: center;

				}

				.contractCard-header-product-container {
				    display: flex;
				    flex-wrap: wrap;
				    justify-content: space-between;
				    align-items: center;
				}

			.contractCard-content {
			    padding: 20px 24px;
			    border-bottom: 1px solid $r2-border;

			    .label {
				    margin-bottom: 15px;
			    }
			}

				.contractCard-subheader {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					&:first-child {
						margin-top: 0;
					}
				}

.contractCard-midSection {
    border-bottom: 1px solid $r2-border;
    padding: 24px 24px 24px 24px;
    position: relative;
}

			.contractCard-occurence {
			    color: $r2-purple;
			    margin-bottom: 10px;


			}

			.contractCard-supplier {
			    margin-bottom: 10px;
			}

			.contractCard-price {
			    display: flex;
			    align-items: flex-end;
			    flex-wrap: wrap;
			    margin: 0 -8px 12px;

			    .form-item--compact {
			        min-width: 100px;
			        margin-bottom: 0;
			    }

			    .tag {
			        padding: 8px 12px;
			    }
			}

			.contractCard-price-col {
			    padding: 0 8px;
			    margin-bottom: 12px;
			}

		.contractCard-footer {
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    width: 100%;

		    padding: 20px 24px;
		}
