.icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon--ellipsis {
    background-image: url("/images/icons/icon-ellipsis.svg");
}

.icon--marker {
    background-image: url("/images/icons/icon-marker.svg");
    width: 16px;
    height: 16px;
    margin-right: 3px;
}

.icon--container {
    background-image: url("/images/icons/icon-container.svg");
    width: 16px;
    height: 16px;
    margin-right: 3px;
}