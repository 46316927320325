.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
	
	@include media-breakpoint-down(sm) {
		display: none;
	}    
}
	
	.app-header-title {
	    font-weight: bold;
	    font-size: 20px;
	}
	
	.app-header-tools {
		display: flex;
		flex-wrap: wrap;
		
	    .btn {
	        background: $r2-purple-20;
	        border: transparent;
	        color: $r2-purple !important;
	    }
	    
	    .btn + .btn {
		    margin-left: 5px;
	    }
	    
	    .app-header-tools-back + .btn {
		    margin-left: 5px;
	    }
	}
	
		.app-header-tools-back {
			display: inline-block;
		}
		
		.app-header-progress {
			display: flex;
			align-items: center;
			
			&:not(&:last-child) {
				margin-right: 15px;
			}
		}
		
			.app-header-progress-bar {
				display: flex;
				align-items: center;
			}
			
				.app-header-progress-bar-step {
					width: 16px;
					height: 4px;
					border-radius: 2px;
					background: $r2-gray;
					margin-right: 4px;
				}
				
						.app-header-progress-bar-step--completed {
							background: $r2-blue;
						}
						
						.app-header-progress-bar-step--active {
							background: $r2-blue;
							width: 32px;
						}
						
			.app-header-progress-label {
				font-weight: bold;
				margin-left: 5px;
			}

.app-subheader {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

	.app-subheader-title {
	    position: relative;
	    font-weight: bold;
	    line-height: 1.3;
	    font-size: 16px;
	
	    small {
	        font-size: 12px;
	        color: $r2-purple;
	        display: block;
	    }
	}
	
			.app-subheader-title--withIcon {
			    padding-left: 60px;
			    min-height: 40px;
			    display: flex;
			    align-items: center;
			}
			
	.app-subheader-icon {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 40px;
	    height: 40px;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}
	
		.app-subheader-icon-image {
		    width: 100%;
		    height: 100%;
		    background-size: contain;
		    background-repeat: no-repeat;
		    background-position: center center;
		    position: absolute;
		    top: 0;
		    left: 0;
		}
	
	.app-subheader-tools {
	    display: flex;
	    justify-content: flex-end;
	
	    .btn {
	        margin-left: 3px;
	    }
	}