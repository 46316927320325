.badge {
    color: $r2-blue;
    background: $r2-purple-5;
    border-radius: 8px;
    height: 16px;
    padding: 3px 7px 2px;
    font-size: 10px;
    position: relative;
    top: -8px;

    &.badge-danger {
        background: $r2-sys-red-bg;
        color: $r2-sys-red;
    }
}
