.modal {
    overflow-y: auto;
}

.modal-content {
    border: none;
    box-shadow: 0 6px 16px rgba(153,155,168,0.1);
}

.modal-header {
    padding: 30px 30px 0;
    border: none;
    display: flex;
    align-items: center;

    .close {
        font-size: 30px;
        font-weight: 300;
        margin-top: -24px;
    }
}

	.modal-header-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		span {
			color: $r2-purple;
			font-size: 14px;
			margin-right: 15px;
		}
	}

.modal-title {
    font-size: 14px;
    font-weight: bold;
    color: $r2-purple;
}

.modal-body {
    padding: 30px;

    h3 {
	    margin-top: 20px;

	    &:first-child {
		    margin-top: 0;
	    }
    }
}

.modal-footer {
    padding: 0 30px 30px;
    border: none;

	> * {
	    margin: 0;
    }

    .btn {
        padding-left: 30px;
        padding-right: 30px;

		+ .btn {
			margin-left: 10px;
		}
    }


}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 900px;
    }
    .modal-2xl {
        max-width: 1400px;
    }
}
