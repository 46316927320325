.app {
    min-height: 100vh;
    position: relative;
}

		.app--login {
		    display: flex;
		    justify-content: center;
		    align-items: center;
		}

		.app--loginPortal {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;

			header {
				width: 100%;
			}
		}

.app-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 100%;
    width: 265px;
    z-index: 810;
    background: white;
    padding: 90px 30px 24px 30px;
}

.app-main {
    padding: 24px 30px 24px 114px;
    transition: padding 0.3s ease;
}

h3.popover-header {
   margin-top: 0 !important;
}

.opacity-30 {
    opacity: .3;
}
