.priceList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 0 0 -15px;

    dt,
    dd {
        width: 50%;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $r2-border;

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    dt {
        font-weight: normal;
        padding-left: 15px;
    }

    dd {
        font-weight: bold;
        text-align: right;
    }

    .priceList-totals {
        font-size: 1.2rem;
    }
}