.form-group.row {
    .control-label {
        @include make-col-ready();

        @include media-breakpoint-up(sm) {
            @include make-col(3);
        }

        padding-top: 4px;
    }

    .col-sm-10 {
        @include make-col-ready();

        @include media-breakpoint-up(sm) {
            @include make-col(9);
        }
    }
}

.input-group {
    flex-wrap: nowrap;

    .select2-container--default .select2-selection--single {
	    background-position: calc(100% - 10px) center;
    }
}

		.input-group--withAdd {
			.input-group-append {
				margin-left: 16px;

				.btn {
			        border-radius: $border-radius !important;
			        height: 40px;
			    }
			}
		}

.input-group-append {


    .input-group-text {
        background: none;
        border: none;
        border-bottom: 1px solid $r2-border;
        border-radius: 0;
        font-weight: bold;
        color: $r2-purple;
        padding: 0 0px 5px 10px;
    }
}

.input-group-prepend {
    .input-group-text {
        background: none;
        border: none;
        border-bottom: 1px solid $r2-border;
        border-radius: 0;
        font-weight: bold;
        color: $r2-purple;
        padding: 0 10px 5px 0;
    }
}

.control-label {
    font-size: 14px;
    color: $r2-purple;
    padding: 0;
    margin-bottom: 2px;
}

#payment_form_payment_method_id {
	position: absolute;
}

.form-item {
    margin-bottom: 15px;

    .fa {
		position: relative;
		z-index: 4;
		font-size: 12px;
		padding: 0px 10px;
		background: white;
	}

	.custom-file {
		margin-top: -8px;
	}
}
		.form-item--compact {
			min-width: 200px;
			text-align: left;
		}

		.form-item--noLabel {
			margin-top: 14px;
		}

		.form-item--large {
			.control-label {
				font-size: 16px;
				margin: 0 0 10px;
				font-weight: bold;
				padding: 0;
			}

			.form-control {
				margin-top: 0;
				min-height: 54px;
				padding: 10px 24px;
			}
		}

		.form-item--compactNoLabel {
			.form-view,
			.form-control,
			.select2-container--default .select2-selection--single,
			.input-group-append,
			.input-group-prepend {
				margin-top: 0;
			}
		}

		.form-item--last {
		    margin-bottom: 0;
		}

		.form-item--date {
		    .form-inline {
		        display: flex;
		        justify-content: space-between;
		    }

		    .form-control {
		        width: 30%;
		        margin-right: 3%;

		        &:last-child {
		            margin-right: 0;
		            width: 34%;
		        }
		    }
		}

		.form-item--checkbox {
			input {
				display: none;
			}

			label {
				padding-left: 30px;
				position: relative;
				min-height: 24px;

				&:before {
					position: absolute;
					left: 0;
					top: 1px;
					width: 20px;
					height: 20px;
					border-radius: 4px;
					content: "";
					background: $r2-gray-border;
				}
			}

			input:checked + label {
				&:before {
					background: var(--check-color);
				}

				&:after {
					width: 10px;
					height: 8px;
					background: url("/images/icons/icon-check.svg") center center no-repeat;
					background-size: contain;
					position: absolute;
					top: 7px;
					left: 5px;
					content: "";
				}
			}

			.form-check {
				padding: 0;
			}

			.form-check-inline {
				.form-check + .form-check {
					margin-left: 15px;
				}
			}
		}

		.form-item--radio {
			margin-bottom: 0;

			input {
				position: absolute !important;
				opacity: 0;
			}

			label {
				padding-left: 30px;
				position: relative;
				min-height: 20px;
				cursor: pointer;
				margin-bottom: 0;

				&:before {
					position: absolute;
					left: 0;
					top: 1px;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					content: "";
					background: $r2-gray-border;

					z-index: 2;
				}
			}

			input:checked + label {

				&:before {
					background: $r2-blue;
				}

				&:after {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-size: contain;
					position: absolute;
					top: 7px;
					left: 6px;
					background: white;
					content: "";
					z-index: 3;
				}
			}

			.form-check {
				padding: 0;
			}

			.form-check-inline {
				.form-check + .form-check {
					margin-left: 15px;
				}
			}
		}

		.form-item--radioInline {
			margin-bottom: 15px;

			> div {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;;
			}

			.form-check:first-child {
				padding-left: 0;
			}
		}

		.form-item--radioMultiple {
			.form-check {
				padding-left: 0;
				margin-bottom: 10px;
			}
		}

		.form-item--inline {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;

		    .control-label {
		        margin-bottom: 0;
		        margin: 0 15px 0 0;
		        padding: 0;
		        font-weight: bold;
		    }

		    .control-input {
			    flex: 1 0 0;

			    .form-control {
				    margin-top: 0;
			    }
		    }
		}

			.field--radio-label {
				display: flex !important;
				align-items: center;
				justify-content: flex-start;
			}

			.form-item-radio-icon {
				width: 40px;
				height: 20px;
				display: inline-block;
				margin-right: 10px;
			}

		.form-item--withImageLabel {
			label {
				padding-left: 60px !important;
				position: relative;
			}

			.form-item-imageLabel {
				position: absolute;
				top: 15px;
				bottom: 15px;
				width: 40px;
				left: 10px;
				display: flex;
				align-content: center;
				align-items: center;
				justify-content: center;

				img {
					max-width: 100%;
					max-height: 100%;
					zoom: 1;
				}
			}
		}

	.form-radios {
		display: flex;
		flex-wrap: wrap;

		.form-item--radio {
			width: 33.33%;
			padding: 5px 20px 5px 30px;

			label {
				border-radius: $border-radius;
				border: 1px solid $r2-border;
				padding: 16px 10px 14px;
				display: block;

				&:before {
					left: 16px;
					top: 50%;
					margin-top: -10px;
				}
				&:after {
					left: 22px !important;
					top: 50% !important;
					margin-top: -4px !important;
				}
			}

			input:checked + label {
				border-color: $r2-blue;
			}
		}
	}

	.form-checkboxes {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;

		.form-item--checkbox {
			width: 50%;
			padding: 0 15px;
		}
	}
			.form-radios--outlined {
				.form-item--radio {
					width: 25%;
					padding-left:  0;

					label {
						padding-left: 52px;
					}
				}
			}

	.control-label {
		background: white;
		padding: 0 4px;
		margin: 0 0 0 8px;
		line-height: 1;
		position: relative;
		z-index: 4;
		max-width: calc(100% - 8px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block !important;
		width: auto !important;

        &.control-label--narrow{
            padding: 0;
            padding-left: 20px !important;
            margin-top: 10px;
        }
	}

	legend.control-label {
		line-height: 22px;
	}

	.form-control {
	    font-weight: bold;
	    border-radius: 0;
	    border: none;
	    border-bottom: 1px solid $r2-border;
	    padding: 4px 0 5px 0;
	    height: 36px;

	    &[disabled] {
	        opacity: 1;
	        color: $r2-purple-40;
	        background: white;
	    }
	}

	.form-control--outlined {
	    border: 1px solid $r2-border;
	    border-radius: $border-radius;
	    padding: 14px 15px;
	    height: 52px;
	}

	.form-control[readonly] {
	//    border-bottom: 1px dashed $r2-border;
	    background: $r2-table-header;
	}

	.form-control:focus {
	    box-shadow: none;
	    outline: none;
	}

	.form-view {
	    font-weight: bold;
	    border-radius: 0;
	    border: none;
	    border-bottom: 1px dashed $r2-border;
	    padding: 8px 0 4px 12px;
	    min-height: 37px;
	    display: flex;
	    justify-content: space-between;
	}

	.form-view,
	.form-control {
		border: 1px solid $r2-border;
		border-radius: $border-radius;
		background: white;
		padding-left: 12px;
		margin-top: -8px;
		min-height: 40px;
	}



	.input-group-prepend {
		margin-top: -8px;

	    .input-group-text {
	        background: none;
	        border: none;
	        border: 1px solid $r2-border;
	        border-radius: $border-radius 0 0 $border-radius;
	        border-right: none;
	        font-weight: bold;

	        padding: 4px 10px 5px 12px;
	    }
	}

	.input-group-append {
		margin-top: -8px;

		.input-group-text {
			padding: 4px 10px 5px 12px;
			border: 1px solid $r2-border;
	        border-radius: 0 $border-radius $border-radius 0;
	        border-left: none;
		}
	}

	.input-group > .form-control:not(:first-child),
	.input-group > .custom-select:not(:first-child) {
		border-left: none;
	}

	.input-group > .form-control:not(:last-child),
	.input-group > .custom-select:not(:last-child) {
		border-right: none;
	}




	.form-item-select {
		position: relative;
		min-height: 40px;

		.form-view {
			position: relative;
		}
	}

			.form-item-select--withAdd {
				padding-right: 56px;
			}

		.form-item-select-add {
			@extend .btn;
			@extend .btn-primary;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 40px;
			height: 40px;
			padding: 8px 8px 0;
		}

		.form-item-select-input {
			position: relative;
			width: 100%;

			input {
				padding: 0;
				border: none;
				width: 100%;
			}

			input:focus {
				outline: none;
			}
		}

			.form-item-select-input-clear {
				position: absolute;
				top: 0px;
				right: 10px;
			}

		.form-item-select-dropdown {
			position: absolute;
			top: 32px;
			min-height: 8px;
			left: -1px;
			right: -1px;
			max-height: 200px;
			background: white;
			box-shadow: 0 6px 16px -6px rgba(153,155,168,0.1);
			border: 1px solid $r2-border;
			border-top: none;
			z-index: 5;
            overflow-y: scroll;
			border-radius: 0 0 $border-radius $border-radius;
		}

			.form-item-select-dropdown-placeholder,
			.form-item-select-dropdown-empty {
				padding: 4px 12px 3px;
			}

			.form-item-select-dropdown-loading {
				position: absolute;
				z-index: 3;
				top: 0; left: 0;
				width: 100%;
				height: 100%;
				background: rgba(255,255,255,0.5);
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.form-item-select-dropdown-results {}

				.form-item-select-dropdown-result {
					padding: 4px 12px 3px;
					font-weight: normal;

					&:hover,
					&:focus {
						background: $r2-table-hover-bg;
					}
				}





.form-imagerow {
    position: relative;
    padding-left: 64px;
    min-height: 64px;
}

.form-imagerow-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    border-radius: $border-radius;
    border: 1px solid $r2-border;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
        zoom: 1;
    }
}

.form-view-icon {
    color: $r2-purple;
}

.multiselect {
    color: inherit;
    min-height: 36px;
    font-size: 14px;

    .multiselect__tags {
        font-weight: bold;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $r2-border;
        padding: 0;
        min-height: 36px;
    }

    .multiselect__input, .multiselect__single {
        font-size: inherit;
        line-height: inherit;
    }


    .multiselect__option--selected.multiselect__option--highlight:after,
    .multiselect__option--highlight {
        background: $r2-purple;
    }

    .multiselect__option--highlight:after {
        display: none;
    }

    .multiselect__content {
        font-size: 14px;
    }

    .multiselect__option {
        min-height: 34px;
        padding: 9px 6px 6px;
    }

    .multiselect__option:after {
        line-height: 34px;
    }

    .multiselect__input {
        margin-bottom: 0;
        border-radius: 0;
        padding: 0;
        height: 36px;
        padding-bottom: 5px;
        font-weight: bold;
    }

    .multiselect__placeholder {
        height: 36px;

        padding-top: 5px;
        line-height: 1.6;
        margin: 0;
    }

    .multiselect__single {
        margin: 0;
        padding: 0 30px 0 0;
        height: 36px;

        padding-top: 5px;
        line-height: 1.6;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:after {
            position: absolute;
            top: 2px;
            right: 0;
            background: url("/images/icons/icon-chevron-down.svg") right center no-repeat;
            background-size: 12px;
            content: "";
            width: 20px;
            height: 20px;
        }
    }
}

select.form-control {
    -webkit-appearance: none;
    background: white url("/images/icons/icon-chevron-down.svg") calc(100% - 10px) center no-repeat;
    background-size: 12px;
}

.select2-container--default {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    border: transparent;
    border-bottom: 1px solid $r2-border;
    border-radius: 0;
    padding: 0 0 5px 0;
    font-weight: bold;
    height: 36px;
    background: white url("/images/icons/icon-chevron-down.svg") right center no-repeat;
    background-size: 12px;

    .select2-selection__rendered {
        padding: 4px 0 5px 0;
        line-height: 22px;
    }

    .select2-selection__clear {
	    display: none;
    }
}

.select2-container--default .select2-selection--single {
	border: 1px solid $r2-border;
	border-radius: $border-radius;
	background: white url("/images/icons/icon-chevron-down.svg") calc(100% - 10px) center no-repeat;
	background-size: 12px;
	margin-top: -8px;
	height: 40px;
	padding: 4px 12px 5px 12px;
}

.select2-container--default:focus {
	outline: none;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: none;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
    outline: none;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background: none;
    border-bottom: 1px dashed $r2-border;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $r2-purple;
}

.select2-selection__arrow {
    display: none;
}

.select2-container--default {
    .select2-selection--multiple {
        border: transparent;
        border-bottom: 1px solid $r2-border;
        border-radius: 0;
        padding: 0;
        font-weight: bold;
        min-height: 36px;
        background: url("/images/icons/icon-chevron-down.svg") right center no-repeat;
        background-size: 12px;
    }

    .select2-dropdown {
        border-color: $r2-border;
    }
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding-left: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background: $r2-purple-5;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $r2-purple;
}


.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: transparent;
    border-bottom: 1px solid $r2-border;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: $r2-purple-5;
    border: transparent;
}

.form-row--inline {
    margin-bottom: 10px;

    .control-label {
        margin-bottom: 0;
        padding-bottom: 5px;
    }

    .form-control {
        height: 27px;
        padding-bottom: 5px;
    }
}

.custom-file {
	display: block;

	input {
		opacity: 0;
	}
}

	.custom-file-label {
		padding: 7px 100px 0 12px;
		border: 1px solid $r2-border;
		height: 40px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:after {
			height: 40px;
			border-left-color: $r2-border;
			background: none;
			padding: 7px 12px 0;
		}
	}

.custom-file-input {
    opacity: inherit;
}

// Date picker

date-input-polyfill {
	z-index: 1000 !important;
}
