.accordion {
    .card {
        border: 1px solid rgb(236, 236, 242);

        .card-header {
            background-color: rgb(251, 251, 253);

            button:focus {
                box-shadow: none;
            }
        }

        .table tbody td:first-child {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .table tbody td:last-child {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 16px;
            text-align: left;
        }
    }
}


.card {
    border-radius: $border-radius-xl;
    margin: 4px 0;
    overflow: hidden;
    position: relative;

    &.item {
        cursor: move;
    }
}

.card--prioHigh {
    border-color: $r2-sys-red;
}

.card--prioMedium {
    border-color: $r2-sys-orange;
}

.card--prioLow {
    border-color: $r2-sys-green;
}

.planner-box--day {
    .card--prioHigh {
        border-color: $r2-sys-red;
        background: $r2-sys-red-bg;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 4px;
            content: "";
            z-index: 1;
            background: $r2-sys-red;
        }
    }

    .card--prioMedium {
        border-color: $r2-sys-orange;
        background: $r2-sys-orange-bg;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 4px;
            content: "";
            z-index: 1;
            background: $r2-sys-orange;
        }
    }

    .card--prioLow {
        border-color: $r2-sys-green;
        background: $r2-sys-green-bg;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 4px;
            content: "";
            z-index: 1;
            background: $r2-sys-green;
        }
    }
}

.card-content {
    padding: 12px 12px;
    display: flex;
}

.card-col {
    padding: 0 12px;
}

.card-col--25 {
    width: 25%;
}

.card-col--20 {
    width: 20%;
}

.card-col--10 {
    width: 10%;
}
