$r2-bg:				#F2F2F6;
$r2-blue-dark:		#0777C7;
$r2-blue-80: #034b7f;
$r2-blue:			#0096FF;
$r2-blue-50:		#008BEC;
$r2-blue-40:		#40B1FF;
$r2-blue-30:		#7FCAFF;
$r2-blue-20:		#BFE5FF;
$r2-blue-10:		#E5F4FF;

$r2-purple:			#8181A5;
$r2-purple-40:		#A1A1BC;
$r2-purple-30:		#C0C0D2;
$r2-purple-20:		#DFDFE8;
$r2-purple-10:		#F2F2F6;
$r2-purple-5:		#F5F5FA;

$r2-sys-red:		#FF808B;
$r2-sys-red-bg:		#fff2f3;
$r2-sys-orange:		#F4BE5E;
$r2-sys-orange-bg:	#fef8ef;
$r2-sys-green-light:#B4F8D6;
$r2-sys-green: 		#4fd68d;
$r2-sys-green-bg:	#e5faee;

$r2-black:			#1C1D21;
$r2-black-40:		#555659;
$r2-black-30:		#8d8e90;
$r2-black-20:		#c6c6c7;
$r2-black-10:		#e8e8e8;

$r2-gray:			#aeaeae;
$r2-gray-border:	#F0F0F3;
$r2-border:			#ececf2;
$r2-table-hover-bg:	#FBFBFD;
$r2-table-header:	#fbfbfd;

$r2-text-gray:		#696F6A;

// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    $r2-blue !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $r2-blue !default;
$secondary:     $r2-purple-5 !default;
$success:       $r2-sys-green !default;
$info:          $cyan !default;
$warning:       $r2-sys-orange !default;
$danger:        $r2-sys-red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Body
$body-bg: $r2-bg;

// Typography
$font-family-sans-serif: "Lato", sans-serif;
$font-size-base: 0.875rem;
$line-height-base: 1.6;

$headings-font-weight:        700 !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius:               4px !default;
$border-radius-xl:            12px !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;



// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-hover-bg:              $r2-table-hover-bg;
