.accordion--boxSet {

}

.boxSet-container {
    display: flex;
    flex-wrap: wrap;

    .boxSet {
        width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: 70%;
            width: 70%
        }
    }

    .boxSet-aside {
        width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: 30%;
            width: 30%;
            padding-left: 24px;
        }

        @include media-breakpoint-up(sm) {
            .box {
                border: 1px solid $r2-border;
                overflow: hidden;
            }
        }
    }
}

.boxSet {
    > .btn {
        margin-bottom: 8px;
    }
}

.boxSet-row {
    > .btn {
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            margin-right: 5px;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        margin: 0 -4px;

        > * {
            margin: 0 4px;
            width: 50%;
        }
    }
}

.boxSet-item {
    background: white;
    border: 1px solid $r2-border;
    border-radius: $border-radius;
    padding: 12px 16px;
    margin-bottom: 8px;
}

.boxSet-item--active {
    .boxSet-item-header {
        &:after {
            transform: rotate(180deg);
        }
    }
}

.boxSet-item--done {
    border-color: $r2-sys-green;

    .boxSet-item-header {
        &:after {
            transform: rotate(0);
            background-image: url("/images/icons/icon-check-green.svg");
        }
    }
}

.boxSet-item-header {
    padding: 12px 0 10px;
    position: relative;
    cursor: pointer;

    h2 {
        font-size: 18px;
        margin: 0;
    }

    &:after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 16px;
        height: 16px;
        background: url("/images/icons/icon-chevron-down.svg") center center no-repeat;
        background-size: contain;
        content: "";
        margin-top: -8px;
    }

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}


.boxSet-item-link {
    &:after {
        transform: rotate(270deg);
    }
}

.boxSet-item-header--support {
    // padding-left: 120px;
    padding-left: 20px;

    h2 {
        margin-bottom: 10px;
    }

    &.with-support-image {
        padding-left: 90px;

        .boxSet-item-header-image {
            position: absolute;
            top: 0;
            left: -6px;
            bottom: -12px;
            width: 70px;
            background-position: left bottom;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
        }
    }

    &:after {
        transform: rotate(-90deg);
    }
}


.boxSet-item-body {
    padding: 12px 0 12px;
}

.boxSet-summary {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.2;
    margin-bottom: -12px;

    @include media-breakpoint-up(md) {
        width: 50%;
    }

    dt {
        font-weight: normal;
        width: 50%;
        padding-right: 20px;
        color: $r2-purple;
        margin-bottom: 12px;
    }

    dd {
        font-weight: normal;
        width: 50%;
        margin-bottom: 12px;
    }

    .status {
        margin-bottom: 0;

        .status-icon {
            top: 0;
        }
    }
}

.boxSet-actions {
    .btn {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;

        .btn {
            width: auto;
            padding: 10px 24px;
            margin: 0 20px 0 0 !important;
        }
    }
}
