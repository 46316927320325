.orderTable-actions {}

	.orderTable-actions-view {
		display: none;
	}

.orderTable-item-price,	
.orderTable-item-mobileActions,
.orderTable-item-header-address {
	display: none;
}

@include media-breakpoint-down(sm) {
	.orderTable {
		font-size: 14px;
		
		tr {
			position: relative;
			padding-right: 40px;
		}
		
		.tag {
			margin-top: 5px;
		}
		
		.btn {
			margin-top: 8px;
			
			&:first-child {
				margin-top: 0;
			}
			
			& + .btn {
				margin-top: 12px;
			}
		}
	}
	
		.orderTable-alerts {
			font-size: 12px;
			display: none !important;
		}
		
			.orderTable-alerts-pickup {
				position: absolute;
				top: 12px;
				left: -25px;
				width: 24px;
				padding: 8px 0;
				background: #fef8ef;
				color: white;
				text-align: center;
			}
			
			.orderTable-alerts-photos {
				position: absolute;
				top: 46px;
				left: -25px;
				width: 24px;
				padding: 8px 0;
				background: #fef8ef;
				color: white;
				text-align: center;
			}
			
		.orderTable-item-header {
			display: flex !important;
			justify-content: flex-start;
			color: $r2-purple;
			margin: 0 -10px;
			
			b {
				color: black;
				font-size: 15px;
			}
			
			.box-product-name {
				font-size: 15px;
				color: black;
				
				.box-product-type {
					font-size: 14px;
				}
			}
		}
		
			.orderTable-item-price {
				display: block;
				color: $r2-purple;
				font-weight: normal;
			}
		
			.box-product-header-teaser {
				width: 50%;
				padding: 0 10px 10px;
				margin-bottom: 10px;
				border-bottom: 1px solid $r2-border !important;
			}
			
			.orderTable-item-header-address {
				display: block;
				width: 50%;
				padding: 0 40px 10px 10px;
				margin-bottom: 10px;
				border-bottom: 1px solid $r2-border !important;			
			}
			
		.orderTable-item-mobileActions {
			display: block;
		}
		
		.orderTable-actions {
			position: absolute;
			top: 20px;
			right: 20px;
			
			.dropdown {
				display: none;
			}
			
			.orderTable-actions-view {
				display: block;
				
				img {
					width: 17px;
				}
			}
		}
}



