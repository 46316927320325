.label {
    display: inline-block;
    border-radius: 4px;
    padding: 9px 15px;
}
		
		.label--small {
		    border-radius: 2px;
		    padding: 2px 4px;
		    font-size: 10px;
		    font-size: 12px;
		}
		
		.label--block {
		    display: block;
		    width: 100%;
		    text-align: center;
		}
		
		.label--success {
		    color: $r2-sys-green;
		}
		
		.label--dark {
		    background: $r2-black;
		    color: white;
		    text-align: center;
		}
		
		.label--light {
		    background: $r2-table-hover-bg;
		    color: $r2-black;
		
		}